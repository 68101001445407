import React, { useState } from 'react';
import { Tag } from 'antd';
import Countdown from '../Common/Countdown';
import moment from 'moment';

const EventTags = ({ event, size, style }) => {
  const { onSaleAt, endSaleAt, startAt, endAt, timezone } = event;
  const [, setState] = useState();
  const rerender = () => {
    //passing empty object will re-render the component
    setState({});
  };

  // This logic is duplicated to front end right now.
  // TODO: better way is probably refetching this info from the server?
  const isEventUpcoming = moment(startAt).isAfter();
  const isEventPast = moment(endAt).isBefore();
  const isSaleUpcoming = moment(onSaleAt).isAfter();
  const isSalePast = moment(endSaleAt).isBefore();

  const isActive = !isEventUpcoming && !isEventPast;
  const isOnSale = !isSaleUpcoming && !isSalePast;

  let inner;

  const tagStyles = {
    fontWeight: 600,
    marginRight: 0,
    fontSize: size === 'large' ? '18px' : '12px',
    padding: size === 'large' ? '0 20px' : '0 7px',
    lineHeight: size === 'large' ? '24px' : '20px',
  };

  if (isEventPast) {
    inner = <Tag style={{ ...tagStyles }}>Event Ended</Tag>;
  } else {
    if (isOnSale) {
      inner = (
        <Tag color="#ff5757" style={{ ...tagStyles }}>
          On Sale!
        </Tag>
      );
    } else if (isSalePast) {
      inner = (
        <Tag color="#da3e27" style={{ ...tagStyles }}>
          Sale Ended
        </Tag>
      );
    } else if (isSaleUpcoming) {
      inner = (
        <Tag color="#fab627" style={{ ...tagStyles }}>
          On Sale in: <Countdown to={onSaleAt} onComplete={rerender} timezone={timezone} />
        </Tag>
      );
    }
  }

  return <div style={{ display: 'flex', flexWrap: 'wrap', ...style }}>{inner}</div>;
};

export default EventTags;
