/**
 * Route that redirects user to homepage if the user is already signed in.
 * Mainly used for Authentication routes as signed in users shouldn't be able to sign in or sign up.
 *
 * @author Michael Yang
 *
 */

import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';

import { AuthContext } from '../context/auth';

function AuthRoute({ component: Component, ...rest }) {
  const { authenticatedUser } = useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={props => (authenticatedUser ? <Redirect to="/" /> : <Component {...props} />)}
    />
  );
}

export default AuthRoute;
