import React, { useState, useEffect, useCallback } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Layout } from 'antd';
import Header from './Header';
import Alert from './Alert';
import Footer from './Footer';
import { useAuth } from '../context/auth';
import Loading from './Loading';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from './ErrorFallback';

const { Content } = Layout;
export const headerOffsetPx = 70;

const Shell = ({ children }) => {
  const [authLoading, setAuthLoading] = useState(true);
  const [checkedAuth, setCheckedAuth] = useState(false);
  const { login: loginAction, isHost } = useAuth();

  const login = useCallback(loginAction, []);
  useEffect(() => {
    const fetchRefreshToken = async () => {
      await fetch(`${process.env.REACT_APP_API_URL}/refresh_token`, {
        method: 'POST',
        credentials: 'include',
      })
        .then(res => res.json())
        .then(({ ok, accessToken }) => {
          // if the refresh token was valid, log the user into the website silently.
          if (ok) {
            login(accessToken);
          }
        })
        .then(() => setCheckedAuth(true));
      setAuthLoading(false);
    };
    fetchRefreshToken();
  }, [setAuthLoading, login]);

  return (
    <Layout
      style={{
        display: 'flex',
        minHeight: '100vh',
        flexDirection: 'column',
        backgroundColor: '#ffffff',
      }}
    >
      <Header heightPx={headerOffsetPx} />
      <Content
        style={{
          backgroundColor: '#fafafa',
          marginTop: `${headerOffsetPx}px`,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {/*{checkedAuth && !isHost && <Alert />}*/}
        <ErrorBoundary
          FallbackComponent={ErrorFallback}
          onReset={() => {
            window.location.reload();
          }}
        >
          {children}
        </ErrorBoundary>
      </Content>
      <Footer />
    </Layout>
  );
};

export default Shell;
