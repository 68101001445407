import React from 'react';
import { Typography } from 'antd';
import moment from 'moment';
const { Text } = Typography;

const DateTimeRange = ({ start, end, timezone, onlyDates, lineBreak = true }) => {
  const startDate = moment.utc(start).tz(timezone);
  const monthStr = startDate.format('MMMM');
  const dateStr = startDate.format('D');
  const dayStr = startDate.format('dddd');
  const startTimeStr = startDate.format('h:mm a');
  const timezoneStr = startDate.format('z');
  const endDate = moment.utc(end).tz(timezone);
  const endTimeStr = endDate.format('h:mm a');

  return (
    <Text>
      {dayStr}, {monthStr} {dateStr}
      {!onlyDates && (
        <>
          {lineBreak ? <br /> : ', '}
          {startTimeStr} {end && `- ${endTimeStr}`} {timezoneStr}
        </>
      )}
    </Text>
  );
};

export default DateTimeRange;
