import React, { useState } from 'react';
import { Button } from 'antd';
import { useAuth } from '../../context/auth';
import moment from 'moment';
import LoginButton from '../Common/LoginButton';
import { useOrderModal } from '../../context/order';
import Countdown from '../Common/Countdown';

const MyOrderButton = ({ event, refetch, style, ...props }) => {
  const { authenticatedUser } = useAuth();

  const { open, orderItems } = useOrderModal();
  const [, setState] = useState();
  const rerender = () => {
    //passing empty object will re-render the component
    setState({});
  };

  const { endAt, onSaleAt, endSaleAt, products, published, timezone } = event;

  const leftoverQuantities = products.reduce((sum, product) => product.leftoverQuantity + sum, 0);

  const totalOrderItemQuantity = orderItems.reduce((currTotal, orderItem) => {
    return currTotal + orderItem.quantity;
  }, 0);
  const noItemsSelected = totalOrderItemQuantity === 0;
  const onLoginSuccess = () => {
    if (!noItemsSelected) {
      open();
    }
  };
  const soldOut = leftoverQuantities === 0;

  let buttonText = `View Cart (${totalOrderItemQuantity} items)`;

  if (noItemsSelected) {
    buttonText = 'No Items in Cart';
  }

  if (soldOut) {
    buttonText = 'Sold Out 😢';
  }

  const isEventPast = moment(endAt).isBefore();
  const isSaleUpcoming = moment(onSaleAt).isAfter();
  const isSalePast = moment(endSaleAt).isBefore();

  if (isEventPast) {
    buttonText = 'Event Ended';
  } else {
    if (isSalePast) {
      buttonText = 'Sale Ended';
    } else if (isSaleUpcoming) {
      buttonText = (
        <div>
          Sale Starts: <Countdown to={onSaleAt} onComplete={rerender} timezone={timezone} />
        </div>
      );
    }
  }

  if (!published) {
    buttonText = 'Event is Not Published Yet';
  }

  const disableButton =
    noItemsSelected || soldOut || isEventPast || !published || isSalePast || isSaleUpcoming;

  if (!authenticatedUser) {
    return (
      <LoginButton type="primary" onFinish={onLoginSuccess} style={style} disabled={disableButton}>
        {buttonText}
      </LoginButton>
    );
  }

  return (
    <Button
      type="primary"
      onClick={disableButton ? null : open}
      style={style}
      disabled={disableButton}
      {...props}
    >
      {buttonText}
    </Button>
  );
};

export default MyOrderButton;
