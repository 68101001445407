import React from 'react';
import { Alert, Card, Descriptions, Typography, Divider } from 'antd';
import DateTimeRange from '../Common/DateTimeRange';
import HostCardHorizontal from '../Host/HostCardHorizontal';
import EventTags from './EventTags';

const { Title, Paragraph } = Typography;

export const EventHeader = props => {
  const { event } = props;
  const {
    name,
    description,
    startAt,
    endAt,
    host,
    published,
    location: { googleMapLink, fullAddress, name: locationName },
    timezone,
  } = event;

  return (
    <Card
      style={{ height: '100%' }}
      bordered={false}
      bodyStyle={{ display: 'flex', flexDirection: 'column', height: '100%' }}
    >
      {!published && (
        <Alert
          message="You are viewing an unpublished event. Ticket purchases are disabled until it is published."
          type="warning"
        />
      )}
      <Title level={3}>{name}</Title>
      <EventTags event={event} />
      <Divider style={{ margin: '16px 0' }} />
      <Descriptions colon={false}>
        <Descriptions.Item label="Host" style={{ display: 'grid', gridTemplateColumns: '1fr 3fr' }}>
          <HostCardHorizontal host={host} />
        </Descriptions.Item>
        <Descriptions.Item label="Time" style={{ display: 'grid', gridTemplateColumns: '1fr 3fr' }}>
          <DateTimeRange start={startAt} end={endAt} timezone={timezone} />
        </Descriptions.Item>
        <Descriptions.Item
          label="Location"
          style={{ display: 'grid', gridTemplateColumns: '1fr 3fr' }}
        >
          <div>
            {locationName} <br />
            {fullAddress} <br />
            <a target="_blank" rel="noopener noreferrer" href={googleMapLink}>
              View Map
            </a>
          </div>
        </Descriptions.Item>
      </Descriptions>
      <Divider />
      <Paragraph style={{ whiteSpace: 'pre-wrap' }}>{description}</Paragraph>
    </Card>
  );
};

export default EventHeader;
