import React from 'react';
import { EditOutlined } from '@ant-design/icons';
import { Button, message } from 'antd';
import CheerModal from './CheerModal';
import { useAuth } from '../../context/auth';
import useHistoryModalState from '../../hooks/useHistoryModalState';

const SendCheerButton = ({ eventId, hostId, userHasOrder, userAlreadyCheered, buttonProps }) => {
  const { authenticatedUser } = useAuth();
  const { open, close, opened } = useHistoryModalState();

  if (!authenticatedUser) {
    return null;
  }

  let disableReason;

  if (!authenticatedUser) {
    disableReason = 'You must be logged in to cheer.';
  }

  if (!userHasOrder) {
    disableReason = 'You have not ordered. Please place an order to send a cheer.';
  }

  if (userAlreadyCheered) {
    disableReason = 'You already cheered for this event.';
  }

  return (
    <>
      <Button
        type="link"
        onClick={disableReason ? () => message.warn(disableReason) : open}
        {...buttonProps}
      >
        Cheer Host <EditOutlined />
      </Button>

      <CheerModal eventId={eventId} hostId={hostId} visible={opened} close={close} />
    </>
  );
};

export default SendCheerButton;
