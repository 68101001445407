import React from 'react';
import { Button, Result } from 'antd';
import * as Sentry from '@sentry/browser';
import Container from './Common/Container';

const ErrorFallback = ({ error, componentStack, resetErrorBoundary }) => {
  Sentry.withScope(scope => {
    scope.setExtra('componentStack', componentStack);
    Sentry.captureException(error);
  });
  return (
    <Container>
      <Result
        status="warning"
        title="This page cannot be loaded at the moment."
        subTitle={
          <div>
            If the problem persists, please contact us at{' '}
            <a href="mailto:support@souskasa.com?subject=Souskasa Website">support@souskasa.com</a>{' '}
            and we will get back to you as soon as possible.
          </div>
        }
        extra={[
          resetErrorBoundary ? <Button onClick={resetErrorBoundary}>Try again</Button> : null,
        ]}
      />
    </Container>
  );
};

export default ErrorFallback;
