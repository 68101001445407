import React from 'react';
import App from './App';
import ApolloClient from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { ApolloLink, Observable, split } from 'apollo-link';
import { WebSocketLink } from 'apollo-link-ws';
import { ApolloProvider } from '@apollo/react-hooks';
import { TokenRefreshLink } from 'apollo-link-token-refresh';
import { getMainDefinition } from 'apollo-utilities';
import { getAccessToken, setAccessToken } from './context/accessToken';
import jwtDecode from 'jwt-decode';
import * as Sentry from '@sentry/browser';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_ENVIRONMENT_NAME,
  release: `${process.env.REACT_APP_VERSION}`,
  // only enable sentry logging in production environments.
  enabled: process.env.NODE_ENV === 'production',
  beforeSend(event, hint) {
    try {
      // if user token exists, include the token.
      event.user = jwtDecode(getAccessToken());
    } catch {}

    return event;
  },
});

const cache = new InMemoryCache();

const tokenRefreshLink = new TokenRefreshLink({
  accessTokenField: 'accessToken',
  isTokenValidOrUndefined: () => {
    const token = getAccessToken();
    if (!token) {
      return true;
    }

    try {
      const { exp } = jwtDecode(token);
      return Date.now() < exp * 1000;
    } catch {
      return false;
    }
  },
  fetchAccessToken: () => {
    return fetch(`${process.env.REACT_APP_API_URL}/refresh_token`, {
      method: 'POST',
      credentials: 'include',
    });
  },
  handleFetch: accessToken => {
    setAccessToken(accessToken);
  },
  handleError: err => {
    console.warn('Your refresh token is invalid. Try to relogin');
    console.error(err);
  },
});

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable(observer => {
      let handle;
      Promise.resolve(operation)
        .then(operation => {
          const accessToken = getAccessToken();
          if (accessToken) {
            operation.setContext({
              headers: {
                authorization: `bearer ${accessToken}`,
              },
            });
          }
        })
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          });
        })
        .catch(observer.error.bind(observer));

      return () => {
        if (handle) handle.unsubscribe();
      };
    })
);

const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_API_URL}/graphql`,
  credentials: 'include',
});

const wsLink = new WebSocketLink({
  uri: `${process.env.REACT_APP_WS_URL}/graphql`,
  options: {
    lazy: true,
    reconnect: true,
    connectionParams: () => {
      const token = getAccessToken();
      return {
        // websocket does not support headers, so we have to use Apollo's connectionParams.
        // Still pass in headers object (mimicking http header) for auth, so it can be handled
        // the same way as http auth header is handled.
        headers: {
          Authorization: token ? `Bearer ${token}` : '',
        },
      };
    },
  },
});

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const splitedLink = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  httpLink
);

const client = new ApolloClient({
  // All of this logic will handle the token refreshing for us silently (as long as the refresh token is valid).
  // You can check the token getting refreshed via the network tab. If we call out to `/refresh_token`, that means we
  link: ApolloLink.from([tokenRefreshLink, requestLink, splitedLink]),
  cache,
});

export default (
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>
);
