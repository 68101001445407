import React from 'react';
import { UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Menu } from 'antd';
import { Link } from 'react-router-dom';
import { useAuth } from '../../context/auth';
import LoginButton from '../Common/LoginButton';

const { SubMenu } = Menu;

const avatar = authenticatedUser => (
  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', cursor: 'pointer' }}>
    <Avatar src={authenticatedUser?.profileImgUrl} icon={<UserOutlined />} size="medium" />
    <span style={{ paddingLeft: '10px' }}>{authenticatedUser?.firstName}</span>
  </div>
);

const profileMenuItem = authenticatedUser => (
  <Menu.Item>
    <Link to={`/users/${authenticatedUser.handle}`}>My Profile</Link>
  </Menu.Item>
);

const ticketsMenuItem = (
  <Menu.Item>
    <Link to={`/tickets`}>My Tickets</Link>
  </Menu.Item>
);

const scanMenuItem = (
  <Menu.Item>
    <Link to={`/scan`}>Scan</Link>
  </Menu.Item>
);

const manageMenuItem = (
  <Menu.Item>
    <Link to={`/manage`}>
      <b>Manage</b>
    </Link>
  </Menu.Item>
);

const logoutMenuItem = logout => (
  <Menu.Item>
    <Button type="link" onClick={logout} danger style={{ padding: 0 }}>
      Logout
    </Button>
  </Menu.Item>
);

const ProfileSubMenu = ({ isDrawer, ...props }) => {
  const { authenticatedUser, isHost, logout } = useAuth();

  return authenticatedUser ? (
    <SubMenu title={avatar(authenticatedUser)} {...props}>
      {profileMenuItem(authenticatedUser)}
      {ticketsMenuItem}
      {/*{isHost && scanMenuItem}*/}
      {isHost && manageMenuItem}
      {logoutMenuItem(logout)}
    </SubMenu>
  ) : (
    <LoginButton
      size="large"
      style={{ marginLeft: isDrawer ? '24px' : '0', marginBottom: isDrawer ? '10px' : '0' }}
    />
  );
};

export default ProfileSubMenu;
