import React from 'react';
import { Button } from 'antd';
import { useLoginRegisterModal } from '../../context/loginRegister';

const RegisterButton = ({ children, onFinish = () => {}, ...props }) => {
  const { openRegister } = useLoginRegisterModal();

  return (
    <Button onClick={() => openRegister(onFinish)} {...props}>
      {children ? children : 'Sign Up'}
    </Button>
  );
};

export default RegisterButton;
