import React, { useState } from 'react';
import { Modal, Button, Divider, Form, Result, Row, Select, Checkbox } from 'antd';

import { useMutation } from '@apollo/react-hooks';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import withStripe from './withStripeHOC';
import gql from 'graphql-tag';
import get from 'lodash.get';
import isNil from 'lodash.isnil';
import sortBy from 'lodash.sortby';
import * as Sentry from '@sentry/browser';
import { FETCH_EVENT_QUERY } from '../../pages/Event';
import { FETCH_TICKETS_QUERY } from '../../utils/graphql';
import { Link } from 'react-router-dom';
import { formatDate } from '../../utils/timeFunction';
import OrderSummaryItem from './OrderSummaryItem';
import { OrderItemEditModal } from './ProductModal';
import moment from 'moment';
import Tip from './Tip';

const { Option } = Select;

const OrderModal = ({
  opened,
  close,
  orderItems,
  event,
  getOrderSummary,
  clearOrderItems,
  isArukaraMember,
  isArukaraEvent,
  forceNormalUser,
  setForceNormalUser,
  tip,
  setTip,
  refetch,
}) => {
  const [error, setError] = useState(null);
  const [editingOrderItemIdx, setEditingOrderItemIdx] = useState(null);

  const [createOrder, { loading: createOrderLoading }] = useMutation(CREATE_ORDER_MUTATION, {
    onError: err => {
      setError(
        get(err, 'graphQLErrors[0].extensions.message', 'Creating order failed unexpectedly.')
      );
      Sentry.captureException(err);
    },
  });

  const [confirmOrderPayment, { loading: confirmOrderPaymentLoading }] = useMutation(
    CONFIRM_ORDER_PAYMENT_MUTATION,
    {
      onCompleted: () => {
        clearOrderItems();
      },
      onError: err => {
        setError(
          get(
            err,
            'graphQLErrors[0].extensions.message',
            'Confirming order payment failed unexpectedly.'
          )
        );
        Sentry.captureException(err);
        Sentry.captureMessage(
          'The payment went through but our callback method failed. This probably means tickets were not created and the quantities are being held.',
          Sentry.Severity.Critical
        );
      },
      refetchQueries: [
        {
          query: FETCH_EVENT_QUERY,
          variables: {
            eventId: parseInt(event.id),
          },
        },
        {
          query: FETCH_TICKETS_QUERY,
        },
      ],
    }
  );

  const orderSummary = getOrderSummary();
  const [form] = Form.useForm();
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [paymentSuccessful, setPaymentSuccessful] = useState(false);
  const [stripeCardInfo, setStripeCardInfo] = useState(null);

  const elements = useElements();
  const stripe = useStripe();
  const orderSummaryItems = orderSummary.orderItems;
  const isFreeOrder = orderSummary.total === 0;

  const reservationCount = orderSummaryItems.reduce(
    (sum, item) =>
      sum +
      (item.product.type === 'TICKET' && item.product.countTowardsReservationLimit
        ? item.quantity
        : 0),
    0
  );

  const onPurchase = async formValues => {
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. This should not happen as the button should be disabled.
      return;
    }

    const { reservationId } = formValues;

    const orderResponse = await createOrder({
      variables: {
        orderItems,
        comment: null,
        reservation: !reservationId
          ? null
          : {
              reservationId,
              reservationCount,
            },
        forceNormalUser,
        tip: tip,
      },
    });

    if (!orderResponse) {
      return;
    }

    let paymentSuccessful = isFreeOrder;

    if (!isFreeOrder) {
      setPaymentLoading(true);
      const result = await stripe.confirmCardPayment(orderResponse.data.createOrder.intentSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
        },
      });

      if (result.error) {
        setError(result.error.message);
      }

      paymentSuccessful = !result.error;

      setPaymentLoading(false);
    }

    // TODO: Move this logic to a stripe callback fn. This doesn't have to get called on the client side.
    await confirmOrderPayment({
      variables: {
        orderId: orderResponse.data.createOrder.order.id,
        paymentSuccessful,
      },
    });
    setPaymentSuccessful(true);
  };

  const isLoading = createOrderLoading || confirmOrderPaymentLoading || paymentLoading;
  let inner;

  const onCloseAfterOrderAttempt = () => {
    // TODO: Be more elegant here.
    window.location.reload();
    // setError(null);
    // setPaymentLoading(false);
    // setPaymentSuccessful(false);
    // setStripeCardInfo(null);
    // refetch();
  };

  if (error) {
    inner = (
      <Result
        status="error"
        title="Something went wrong with your order."
        subTitle={error}
        extra={[
          <Button key="backButton" onClick={onCloseAfterOrderAttempt}>
            Back
          </Button>,
        ]}
      />
    );
  } else if (paymentSuccessful) {
    inner = (
      <Result
        status="success"
        title="Order Successful!"
        subTitle="We look forward to seeing you! We will send an email to you regarding this order."
        extra={[
          <Link to="/tickets" key="ticketLink">
            <Button type="primary" onClick={close}>
              View Tickets
            </Button>
          </Link>,
          <Button onClick={onCloseAfterOrderAttempt} key="closeButton">
            Close
          </Button>,
        ]}
      />
    );
  } else {
    inner = (
      <>
        {isArukaraMember && isArukaraEvent && (
          <div>
            <div>
              <strong>Welcome Arukara passholder! Please take the time to read this...</strong>
              <div style={{ marginBottom: '4px' }}>All passholders get extra noodle for free!</div>
              <div style={{ marginBottom: '4px' }}>
                If you are a <strong>limited</strong> passholder and would like extra beef, you can
                choose and pay for it here!
              </div>
              <div style={{ marginBottom: '4px' }}>
                If you are an <strong>unlimited</strong> passholder and would like extra beef,
                please choose <strong>regular</strong> as the beef option and put it in the{' '}
                <strong>order comment.</strong>
              </div>
              <i style={{ marginBottom: '4px' }}>
                You can get up to <strong>2 bowls per event</strong> using the pass.{' '}
              </i>
              <i style={{ marginBottom: '4px' }}>
                If you would like to order more than 2 bowls, please create an order for the 2 bowls
                you want for free, then make a separate order as a non-passholder by checking the
                box below.
              </i>
            </div>
            <strong>Check to buy as a non-passholder. </strong>
            <Checkbox
              onChange={() => setForceNormalUser(prev => !prev)}
              checked={forceNormalUser}
            />
            <Divider />
          </div>
        )}
        <Form onFinish={onPurchase} form={form} name="purchase-flow" layout="vertical">
          {orderSummaryItems.map((orderSummaryItem, idx) => (
            <div key={'orderSummaryItem' + idx}>
              <OrderSummaryItem
                orderSummaryItem={orderSummaryItem}
                orderItemIdx={idx}
                setEditingIdx={setEditingOrderItemIdx}
              />
              <Divider />
            </div>
          ))}
          {event.tipEnabled && (
            <Tip
              subTotal={orderSummary.subTotal}
              tip={orderSummary.tip}
              setTip={setTip}
              tipNotes={event.tipNotes}
            />
          )}
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', fontSize: '16px', justifyContent: 'space-between' }}>
              <span>Subtotal</span>
              {orderSummary.subTotal === 0 ? (
                <span>Free!</span>
              ) : (
                <span>${orderSummary.subTotal.toFixed(2)}</span>
              )}
            </div>
            <div style={{ display: 'flex', fontSize: '16px', justifyContent: 'space-between' }}>
              <span>Taxes</span>
              {orderSummary.fees === 0 ? (
                <span>(Tax Included)</span>
              ) : (
                <span>${orderSummary.fees.toFixed(2)}</span>
              )}
            </div>
            {event.tipEnabled && (
              <div style={{ display: 'flex', fontSize: '16px', justifyContent: 'space-between' }}>
                <span>Tip</span>
                <span>
                  ${orderSummary.tip ? orderSummary.tip.toFixed(2) : Number(0).toFixed(2)}
                </span>
              </div>
            )}
            <div style={{ display: 'flex', fontSize: '16px', justifyContent: 'space-between' }}>
              <strong>Total</strong>
              {orderSummary.total === 0 ? (
                <strong>Free!</strong>
              ) : (
                <strong>${orderSummary.total.toFixed(2)}</strong>
              )}
            </div>
          </div>
          <Divider />
          {!event.reservations || !event.reservations.length ? null : (
            <>
              <Form.Item
                label={<div>Estimated Pick-up Time</div>}
                name="reservationId"
                rules={[{ required: true, message: 'Please provide the reservation time.' }]}
              >
                <Select
                  style={{ width: '100%' }}
                  size="large"
                  notFoundContent="No more timeslots are available :("
                >
                  {sortBy(event.reservations, reservation => reservation.reserveAt)
                    .filter(
                      reservation =>
                        !moment(reservation.reserveAt).isBefore(moment().add(2, 'minutes'))
                    )
                    .map(reservation => {
                      const hasTotalLimit = !isNil(reservation.totalQuantity);
                      const hasAbsoluteTotalLimit = !isNil(reservation.absoluteTotalQuantity);

                      let optionSubText = '';
                      let disabled = false;
                      if (hasTotalLimit || hasAbsoluteTotalLimit) {
                        const leftoverQuantity = reservation.leftoverQuantity;

                        let soldOut = isNil(reservation.totalQuantity)
                          ? reservation.absoluteTotalQuantity - reservation.soldQuantity <= 0
                          : reservation.totalQuantity - reservation.soldQuantity <= 0;
                        let exceedsAbsoluteLimit = leftoverQuantity - reservationCount < 0;
                        // TODO: Remove this
                        if (event.id === 816) {
                          soldOut = reservation.soldQuantity >= 3;
                          exceedsAbsoluteLimit = reservation.soldQuantity + 1 > 3;
                        }
                        // if reservation count is 0, then all options should be available.
                        disabled = reservationCount > 0 && (soldOut || exceedsAbsoluteLimit);
                        optionSubText = soldOut ? '(Sold Out)' : `(${leftoverQuantity} left)`;
                      }
                      return (
                        <Option
                          value={reservation.id}
                          key={`reservation${reservation.id}`}
                          disabled={disabled}
                        >
                          {formatDate(reservation.reserveAt, 'MM/DD hh:mm A', event.timezone)}
                          {reservationCount > 0 && (
                            <small style={{ marginLeft: '6px' }}>{optionSubText}</small>
                          )}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
              <Divider />
            </>
          )}
          <p>Payment Information</p>
          {isFreeOrder ? (
            <h5>This is a free order. No payments necessary.</h5>
          ) : (
            <CardElement
              onChange={cardInfo => setStripeCardInfo(cardInfo)}
              options={{
                style: {
                  base: {
                    fontSize: '16px',
                    color: '#424770',
                    fontFamily: "'Avenir', Helvetica, sans-serif",
                    '::placeholder': {
                      color: '#aab7c4',
                    },
                  },
                  invalid: {
                    color: '#9e2146',
                  },
                },
              }}
            />
          )}
          <Row style={{ margin: '15px 0' }}>
            <small style={{ userSelect: 'none' }}>
              Ticket purchases are final and non-refundable. By purchasing tickets, you are agreeing
              to our{' '}
              <a href="/terms" target="_blank">
                Terms and Conditions
              </a>
              .
            </small>
          </Row>
          <Form.Item shouldUpdate style={{ textAlign: 'right', marginBottom: 0 }}>
            {({ getFieldsValue }) => {
              const hasOrderItems =
                orderItems.length > 0 && orderItems.every(orderItem => orderItem.quantity > 0);

              const hasCardInfo = isFreeOrder || (stripeCardInfo && stripeCardInfo.complete);

              return (
                <Button
                  block
                  type="primary"
                  htmlType="submit"
                  disabled={!hasOrderItems || !hasCardInfo || !stripe || !elements || error}
                  loading={isLoading}
                  size="large"
                >
                  Purchase
                </Button>
              );
            }}
          </Form.Item>
        </Form>
      </>
    );
  }

  return (
    <>
      <Modal title="Cart" visible={opened} footer={null} centered onCancel={close}>
        {inner}
      </Modal>
      <OrderItemEditModal
        close={() => setEditingOrderItemIdx(null)}
        visible={!isNil(editingOrderItemIdx)}
        orderItemIdx={editingOrderItemIdx}
      />
    </>
  );
};

const CREATE_ORDER_MUTATION = gql`
  mutation createOrder(
    $orderItems: [CreateOrderRequestOrderItem!]!
    $comment: String
    $reservation: CreateOrderRequestReservation
    $forceNormalUser: Boolean
    $tip: Float
  ) {
    createOrder(
      request: {
        orderItems: $orderItems
        comment: $comment
        reservation: $reservation
        forceNormalUser: $forceNormalUser
        tip: $tip
      }
    ) {
      order {
        id
        status
      }
      intentSecret
    }
  }
`;

export const CONFIRM_ORDER_PAYMENT_MUTATION = gql`
  mutation confirmOrderPayment($orderId: String!, $paymentSuccessful: Boolean!) {
    confirmOrderPayment(orderId: $orderId, paymentSuccessful: $paymentSuccessful) {
      id
      status
    }
  }
`;

export default withStripe(OrderModal);
