import React, { useEffect, useState } from 'react';
import gql from 'graphql-tag';
import EventHeader from '../components/Event/EventHeader';
import Loading from '../components/Loading';
import Container from '../components/Common/Container';
import { Typography, Affix, Col, Row, Divider } from 'antd';
import Products from '../components/Event/Products';
import CustomHelmet from '../components/Common/CustomHelmet';
import {
  eventFragment,
  hostFragment,
  locationFragment,
  productFragment,
  eventReservationFragment,
  productOptionFragment,
  cheerFragment,
  hostStatsFragment,
} from '../utils/graphql';
import MyOrderButton from '../components/Event/MyOrderButton';
import { useOrderModal } from '../context/order';
import ImageList from '../components/Common/ImageList';
import SendCheerButton from '../components/Cheer/SendCheerButton';
import { useIsMobile, useIsTablet } from '../hooks/useMediaQuery';
import { useAuth } from './../context/auth';
import { useParams } from 'react-router-dom';
import { ASPECT_RATIO } from '../components/Common/Image';

const { Title } = Typography;

const Event = () => {
  const { authenticatedUser } = useAuth();
  const { eventId } = useParams();
  const { setEventId, eventLoading, event, refetch } = useOrderModal();
  const [isOrderButtonAffixed, setIsOrderButtonAffixed] = useState(false);
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();

  useEffect(() => {
    setEventId(parseInt(eventId));
  }, [eventId, setEventId]);

  if (eventLoading) {
    return <Loading />;
  }

  if (!event) {
    return null;
  }

  const { mainImgUrl, products, cheers, userHasOrder } = event;

  const userAlreadyCheered =
    authenticatedUser &&
    cheers?.some(cheer => cheer.user?.uuid === authenticatedUser.id && cheer.eventId === eventId);
  const productImgUrls = products.map(product => product.imgUrl);

  return (
    <>
      <Container noPaddingBreakPoint="sm">
        <CustomHelmet
          type="events.event"
          title={`${event.name} by ${event.host.displayName}`}
          description={event.description}
          image={event.mainImgUrl}
        >
          <meta name="og:event:start_time" content={event.startAt} />
          <meta name="og:event:end_time" content={event.endAt} />
        </CustomHelmet>
        <Row>
          <Col
            sm={24}
            md={14}
            style={{ width: '100%', display: 'flex', alignItems: 'center', background: '#fff' }}
          >
            <ImageList
              imageUrls={[mainImgUrl, ...productImgUrls]}
              maxPreview={1}
              hideViewMoreOverlay
            />
          </Col>
          <Col sm={24} md={10}>
            <EventHeader event={event} products={products} />
          </Col>
        </Row>
        <Container style={{ paddingTop: '12px' }}>
          <Container horizontal style={{ padding: '0' }}>
            <Title level={4} style={{ margin: 0 }}>
              Cheers
            </Title>
            <SendCheerButton
              eventId={event.id}
              hostId={event.host.id}
              userHasOrder={userHasOrder}
              userAlreadyCheered={userAlreadyCheered}
            />
          </Container>
          <small style={{ marginBottom: '8px' }}>
            Share your love for <strong>{event.host.displayName}</strong> with your drawings!
          </small>
          <ImageList
            imageUrls={cheers.map(cheer => cheer.imageUrl)}
            maxPreview={isMobile ? 4 : isTablet ? 8 : 12}
            previewImageProps={{
              aspectRatio: ASPECT_RATIO.one_by_one,
              useIntrinsicDimensions: true,
              fit: 'contain',
            }}
          />
        </Container>
        <Divider />
        <Container style={{ paddingTop: '12px' }}>
          <Title level={4}>Menu</Title>
          <Products
            products={products.filter(product => product.type === 'TICKET')}
            event={event}
          />
        </Container>
        {products.some(product => product.type === 'MERCH') && (
          <Container style={{ paddingTop: '12px' }}>
            <Title level={4}>Merch</Title>
            <Products
              products={products.filter(product => product.type === 'MERCH')}
              event={event}
            />
          </Container>
        )}
      </Container>
      <Affix
        offsetBottom={0}
        style={{ zIndex: 20 }}
        onChange={affixed => setIsOrderButtonAffixed(affixed)}
      >
        <div
          style={
            isOrderButtonAffixed
              ? {
                  backgroundColor: '#fff',
                  boxShadow: '0px -2px 0px rgba(0, 0, 0, 0.05)',
                  padding: '16px',
                  width: '100%',
                  textAlign: 'center',
                }
              : { padding: '16px', width: '100%', textAlign: 'center' }
          }
        >
          <MyOrderButton
            event={event}
            refetch={refetch}
            size="large"
            style={{
              width: '100%',
              margin: '0 auto',
              maxWidth: '552px',
              display: 'block',
              transition: 'width 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
            }}
          />
        </div>
      </Affix>
    </>
  );
};

const FETCH_EVENT_QUERY = gql`
  query getEvent($eventId: Float!) {
    eventById(id: $eventId) {
      ...EventFragment
      location {
        ...LocationFragment
      }
      host {
        ...HostFragment
        stats {
          ...HostStatsFragment
        }
      }
      products {
        ...ProductFragment
        options {
          ...ProductOptionFragment
        }
      }
      reservations {
        ...EventReservationFragment
      }
      cheers {
        ...CheerFragment
        user {
          uuid
        }
      }
      userHasOrder
    }
  }
  ${eventFragment}
  ${locationFragment}
  ${hostFragment}
  ${productFragment}
  ${productOptionFragment}
  ${eventReservationFragment}
  ${cheerFragment}
  ${hostStatsFragment}
`;

export default Event;
export { FETCH_EVENT_QUERY };
