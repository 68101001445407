import { useMutation } from '@apollo/react-hooks';
import { GENERATE_PRESIGNED_URL_MUTATION } from '../components/Review/ImageUploader';

export const dataURItoBlob = dataURI => {
  const binary = atob(dataURI.split(',')[1]);
  const array = [];
  for (let i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }
  return new Blob([new Uint8Array(array)], { type: 'image/jpeg' });
};

const useUpload = () => {
  const [generatePreSignedUploadUrl] = useMutation(GENERATE_PRESIGNED_URL_MUTATION);

  const upload = async ({ contentType, fileName, data }) => {
    const {
      data: { generatePreSignedUploadUrlForImage: urls },
    } = await generatePreSignedUploadUrl({
      variables: { contentType, fileName },
    });

    const { getUrl, putUrl } = urls;
    await fetch(putUrl, {
      method: 'PUT',
      headers: { 'Content-Type': contentType },
      body: data,
    });

    return getUrl;
  };

  return { upload };
};

export default useUpload;
