import React from 'react';
import { Divider, Radio } from 'antd';
import NumberFormat from 'react-number-format';
import round from 'lodash.round';

const Tip = ({ subTotal, tip, setTip, tipNotes }) => {
  return (
    <div>
      <div style={{ fontSize: '16px', marginBottom: '4px' }}>Tip</div>
      {tipNotes && (
        <div>
          <i>Note: {tipNotes}</i>
        </div>
      )}
      {/* Don't show the radio boxes if this is a free order. */}
      {subTotal !== 0 && (
        <Radio.Group
          value={tip}
          buttonStyle="solid"
          onChange={e => {
            setTip(e.target.value);
          }}
        >
          <Radio.Button value={0} style={{ flex: 1 }}>
            <span style={{ fontSize: '1.125em', display: 'block' }}>No Tip</span>
          </Radio.Button>
          <Radio.Button
            value={parseFloat(round(subTotal * 0.15, 25).toFixed(2))}
            style={{ flex: 1 }}
          >
            <span style={{ fontSize: '1.125em', display: 'block' }}>15%</span>
            <small>${round(subTotal * 0.15, 25).toFixed(2)}</small>
          </Radio.Button>
          <Radio.Button
            value={parseFloat(round(subTotal * 0.18, 28).toFixed(2))}
            style={{ flex: 1 }}
          >
            <span style={{ fontSize: '1.125em', display: 'block' }}>18%</span>
            <small>${round(subTotal * 0.18, 28).toFixed(2)}</small>
          </Radio.Button>
          <Radio.Button value={parseFloat(round(subTotal * 0.2, 2).toFixed(2))} style={{ flex: 1 }}>
            <span style={{ fontSize: '1.125em', display: 'block' }}>20%</span>
            <small>${round(subTotal * 0.2, 2).toFixed(2)}</small>
          </Radio.Button>
        </Radio.Group>
      )}
      <span className="ant-input-affix-wrapper" style={{ marginTop: '4px' }}>
        <NumberFormat
          thousandSeparator
          prefix={'$'}
          value={tip}
          isAllowed={({ formattedValue, floatValue }) =>
            formattedValue === '' || (floatValue >= 0 && floatValue <= 1000)
          }
          className="ant-input"
          decimalScale={2}
          allowNegative={false}
          onValueChange={({ formattedValue, floatValue }) => {
            setTip(formattedValue === '' ? 0 : floatValue);
          }}
        />
      </span>
      <Divider />
    </div>
  );
};

export default Tip;
