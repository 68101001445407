import React, { useEffect } from 'react';
import sortBy from 'lodash.sortby';
import { Button, Checkbox, Form, Input, Radio, Select, Typography } from 'antd';
import range from 'lodash.range';
import flatMap from 'lodash.flatmap';
const { useForm } = Form;
const { Text } = Typography;

const OrderItemForm = ({ product, onFinish, initialOrderItem, formSubmitButtonText }) => {
  const [form] = useForm();

  const validZipCodes = ['02140', '02138', '02143', '02144', '02145', '02139'];

  useEffect(() => {
    const formInitialValues = initialOrderItem
      ? {
          comment: initialOrderItem.comment,
          quantity: initialOrderItem.quantity,
          options: initialOrderItem.options.reduce((map, orderItemOption) => {
            const productOption = product.options.find(po => po.id === orderItemOption.optionId);
            // if no need to be do anything, return the map.
            if (!productOption || !orderItemOption.choices || !orderItemOption.choices.length) {
              return map;
            }

            map[orderItemOption.optionId] = productOption.isRadio
              ? orderItemOption.choices[0].optionChoiceId
              : orderItemOption.choices.map(choice => choice.optionChoiceId);

            return map;
          }, {}),
        }
      : {
          quantity: 1,
        };

    form.setFieldsValue(formInitialValues);
  }, [initialOrderItem, form, product.options]);

  const onSubmit = formValues => {
    const options = getOrderItemOptions(formValues.options);

    const orderItem = {
      productId: product.id,
      options,
      comment: formValues.comment,
      quantity: formValues.quantity,
    };

    onFinish(orderItem);
  };

  const getOrderItemTotalPrice = (quantity, formValues) => {
    quantity = parseInt(quantity);
    const options = getOrderItemOptions(formValues.options);

    const choiceIds = flatMap(options, option =>
      option.choices.map(choice => choice.optionChoiceId)
    );

    const productOptionChoices = flatMap(product.options, option => option.choices);
    const selectedChoices = choiceIds
      .map(choiceId => productOptionChoices.find(choice => choice.id === choiceId))
      .filter(choice => !!choice);

    const totalUnitPriceAdjustment = selectedChoices.reduce(
      (sum, choice) => sum + (choice.listPriceAdjustment ? choice.listPriceAdjustment : 0),
      0
    );

    const totalUnitPrice = product.listPrice + totalUnitPriceAdjustment;

    // set negatives to 0.
    return Math.max(0, totalUnitPrice * quantity);
  };

  const getOrderItemOptions = options => {
    const optionIds = options ? Object.keys(options) : [];

    return (
      optionIds
        .map(optionId => {
          const option = product.options.find(option => option.id === optionId);

          if (!option) {
            return null;
          }
          const choiceIdOrIds = options[optionId];

          if (!choiceIdOrIds) {
            return null;
          }

          const isMultiple = Array.isArray(choiceIdOrIds);

          const choiceIds = isMultiple ? choiceIdOrIds : [choiceIdOrIds];

          const optionChoices = choiceIds.map(choiceId => ({ optionChoiceId: choiceId }));

          return {
            optionId,
            choices: optionChoices,
          };
        })
        // sanitize by filtering out all the null/undefined.
        .filter(choice => !!choice)
    );
  };

  const soldOut = product.leftoverQuantity === 0;

  const leftoverMaxPurchasableQuantity = Math.min(
    product.leftoverQuantity,
    product.maxPurchasableQuantity
  );

  const optionChoiceStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
    marginLeft: '0',
  };

  return (
    <Form form={form} onFinish={onSubmit} layout="vertical" initialValues={{ quantity: 1 }}>
      {sortBy(product.options, option => parseInt(option.id)).map(option =>
        option.isRadio ? (
          <Form.Item
            shouldUpdate
            key={`option${option.id}`}
            name={['options', option.id]}
            label={
              <div>
                <Text type="secondary" style={{ fontSize: '16px', display: 'block' }}>
                  {option.name}
                </Text>
                <small>{option.description}</small>
              </div>
            }
            rules={option.isRequired && [{ required: true, message: 'Please select an option' }]}
          >
            <Radio.Group style={{ whiteSpace: 'normal' }} size="large">
              {sortBy(option.choices, choice => parseInt(choice.id)).map(choice => (
                <Radio
                  key={`option${option.id}-${choice.id}`}
                  style={optionChoiceStyle}
                  value={choice.id}
                >
                  {choice.name}{' '}
                  {choice.listPriceAdjustment || choice.listPriceAdjustment > 0
                    ? `($${choice.listPriceAdjustment.toFixed(2)})`
                    : ''}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
        ) : (
          <>
            <Form.Item
              shouldUpdate
              key={`option${option.id}`}
              name={['options', option.id]}
              label={
                <div>
                  <Text type="secondary" style={{ fontSize: '16px', display: 'block' }}>
                    {option.name}
                  </Text>
                  <small>{option.description}</small>
                </div>
              }
              rules={option.isRequired && [{ required: true, message: 'Please select an option' }]}
            >
              <Checkbox.Group>
                {sortBy(option.choices, choice => parseInt(choice.id)).map(choice => (
                  <Checkbox
                    key={`option${option.id}-${choice.id}`}
                    style={optionChoiceStyle}
                    value={choice.id}
                  >
                    {choice.name}{' '}
                    {choice.listPriceAdjustment || choice.listPriceAdjustment > 0
                      ? `($${choice.listPriceAdjustment.toFixed(2)})`
                      : ''}
                  </Checkbox>
                ))}
              </Checkbox.Group>
            </Form.Item>

            {option.name === 'Delivery' && (
              <Form.Item
                shouldUpdate
                name="zipCode"
                rules={[
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      const optionChoices =
                        (getFieldValue('options') && getFieldValue('options')[option.id]) || [];
                      // only check zip code if the delivery option is chosen.
                      return optionChoices.length === 0 ||
                        validZipCodes.some(valid => valid === value)
                        ? Promise.resolve()
                        : Promise.reject('We cannot deliver to the specified zip code.');
                    },
                  }),
                ]}
              >
                <Input placeholder="Zip Code (for delivery only)" />
              </Form.Item>
            )}
          </>
        )
      )}
      <Form.Item
        shouldUpdate
        name="quantity"
        label={
          <Text type="secondary" style={{ fontSize: '16px' }}>
            Quantity
          </Text>
        }
        rules={[{ required: true, message: 'Please select the quantity' }]}
      >
        <Select disabled={!leftoverMaxPurchasableQuantity}>
          {range(1, leftoverMaxPurchasableQuantity + 1).map(cnt => (
            <Select.Option key={`quantity-${cnt}`} value={cnt}>
              {cnt}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      {product.acceptComment && (
        <Form.Item
          shouldUpdate
          name="comment"
          label={
            <Text type="secondary" style={{ fontSize: '16px' }}>
              Comment
            </Text>
          }
        >
          <Input.TextArea
            placeholder="Please comment anything like allergies and dietary restrictions."
            autoSize={{ minRows: 2, maxRows: 4 }}
            maxLength="250"
          />
        </Form.Item>
      )}
      <Form.Item shouldUpdate>
        {form => {
          const quantity = form.getFieldValue('quantity');
          const currentValues = form.getFieldsValue();
          const totalPrice = getOrderItemTotalPrice(quantity, currentValues);

          let buttonText = formSubmitButtonText
            ? formSubmitButtonText
            : `Add ${quantity} To Cart (${
                totalPrice === 0 ? 'Free!' : `$${totalPrice.toFixed(2)}`
              })`;

          if (soldOut) {
            buttonText = 'Sold Out 😢';
          }
          return (
            // TODO: Make this guy fixed at the bottom.
            <Button
              disabled={soldOut}
              type="primary"
              size="large"
              htmlType="submit"
              block
              style={{ margin: '20px 0' }}
            >
              {buttonText}
            </Button>
          );
        }}
      </Form.Item>
    </Form>
  );
};

export default OrderItemForm;
