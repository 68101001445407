import React from 'react';

const Center = ({ children, style, ...rest }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        flexGrow: 1,
        height: '100%',
        ...style,
      }}
      {...rest}
    >
      {children}
    </div>
  );
};

export default Center;
