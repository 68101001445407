import React from 'react';
import styled, { keyframes } from 'styled-components';

const pulse = keyframes`
    0% {
        transform: scale(1.1);
    }
    50% {
        transform: scale(0.9);
    }
    51% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(0.9);
    }
`;

const StyledSvg = styled.svg`
  animation: ${pulse} 1.6s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
`;

const LoadingIcon = () => {
  return (
    <StyledSvg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      viewBox="0 0 511.998 511.998"
    >
      <g>
        <path
          fill="#FA9EB0"
          d="M51.331,298.13c-9.593,10.084-16.754,17.612-37.567,18.834c-3.522,0.207-6.264,3.148-6.264,6.676
		v22.396c0,3.834,3.22,6.877,7.049,6.686c35.908-1.798,50.679-17.324,62.687-29.948c10.459-10.994,17.368-18.257,41.105-18.257
		v-35.755C79.269,268.76,63.778,285.046,51.331,298.13z"
        />
        <path
          fill="#FA9EB0"
          d="M498.236,316.964c-20.813-1.222-27.974-8.75-37.567-18.834
		c-12.448-13.084-27.939-29.369-67.011-29.369v35.755c23.737,0,30.646,7.264,41.105,18.257
		c12.008,12.624,26.779,28.15,62.687,29.948c3.83,0.192,7.05-2.851,7.05-6.686V323.64
		C504.5,320.112,501.759,317.171,498.236,316.964z"
        />
      </g>
      <g>
        <path
          fill="#ED6883"
          d="M442.466,281.807c-10.859-7.355-25.712-13.046-48.808-13.046v35.755
		c23.737,0,30.646,7.264,41.105,18.258c2.406,2.529,4.927,5.175,7.703,7.803L442.466,281.807L442.466,281.807z"
        />
        <path
          fill="#ED6883"
          d="M69.534,281.807c10.859-7.355,25.712-13.046,48.808-13.046v35.755
		c-23.737,0-30.646,7.264-41.105,18.258c-2.406,2.529-4.927,5.175-7.703,7.803V281.807z"
        />
      </g>
      <path
        fill="#FA9EB0"
        d="M398.557,216.297c-29.238-27.221-32.562-63.786-32.562-63.786l-0.597-0.088V30.387h-48.876V63.89
	c0,33.425-27.096,60.522-60.522,60.522l0,0c-33.425,0-60.522-27.096-60.522-60.522V30.387h-49.472v122.124
	c0,0-3.324,36.565-32.562,63.786c-8.793,8.186-13.91,19.573-13.91,31.587v205.742c0,15.457,12.53,27.987,27.987,27.987h256.958
	c15.457,0,27.987-12.53,27.987-27.987V247.884C412.466,235.87,407.35,224.483,398.557,216.297z"
      />
      <path
        fill="#ED6883"
        d="M256,302.336l-85.625,15.697v54.545c0,14.213,11.531,25.744,25.744,25.744h119.763
	c14.213,0,25.744-11.531,25.744-25.744v-54.545L256,302.336z"
      />
      <rect x="170.37" y="286.64" fill="#F3F5E6" width="171.25" height="31.393" />
      <path
        d="M188.506,228.257c4.142,0,7.5-3.358,7.5-7.5v-12.215c0-4.142-3.358-7.5-7.5-7.5s-7.5,3.358-7.5,7.5v12.215
	C181.006,224.899,184.364,228.257,188.506,228.257z"
      />
      <path
        d="M323.494,228.257c4.142,0,7.5-3.358,7.5-7.5v-12.215c0-4.142-3.358-7.5-7.5-7.5s-7.5,3.358-7.5,7.5v12.215
	C315.994,224.899,319.352,228.257,323.494,228.257z"
      />
      <path
        d="M256,234.778c8.657,0,16.786-3.621,22.305-9.934c2.726-3.119,2.408-7.857-0.711-10.583
	c-3.119-2.726-7.857-2.408-10.583,0.711c-2.67,3.054-6.683,4.806-11.011,4.806s-8.341-1.752-11.011-4.806
	c-2.727-3.119-7.464-3.437-10.583-0.711s-3.437,7.464-0.711,10.583C239.214,231.157,247.343,234.778,256,234.778z"
      />
      <path
        d="M82.67,327.943c3.455-3.631,6.301-6.614,9.364-8.983v134.665c0,19.568,15.919,35.487,35.487,35.487h256.958
	c19.568,0,35.487-15.919,35.487-35.487V318.96c3.063,2.369,5.909,5.352,9.363,8.982c12.172,12.796,28.842,30.322,67.747,32.27
	c0.24,0.012,0.479,0.018,0.716,0.018l0,0c3.777,0,7.334-1.467,10.017-4.131c2.702-2.683,4.189-6.257,4.189-10.063V323.64
	c0-7.503-5.853-13.724-13.324-14.164c-17.858-1.048-23.396-6.871-32.572-16.516c-9.43-9.913-22.086-23.198-46.137-28.875v-16.202
	c0-13.918-5.941-27.432-16.3-37.076c-26.658-24.819-30.173-58.654-30.204-58.976c-0.072-0.793-0.267-1.551-0.566-2.254V30.387
	c0-4.142-3.358-7.5-7.5-7.5h-48.876c-4.142,0-7.5,3.358-7.5,7.5V63.89c0,29.236-23.785,53.022-53.021,53.022
	s-53.021-23.786-53.021-53.022V30.387c0-4.142-3.358-7.5-7.5-7.5h-49.473c-4.142,0-7.5,3.358-7.5,7.5v121.73
	c-0.384,3.331-4.659,34.938-30.173,58.691c-10.358,9.644-16.298,23.157-16.298,37.076v16.202
	c-24.052,5.676-36.708,18.962-46.139,28.877c-9.175,9.644-14.713,15.465-32.571,16.514C5.853,309.915,0,316.137,0,323.64v22.396
	c0,7.826,6.374,14.193,14.207,14.194c0,0,0.001,0,0.002,0c0.237,0,0.475-0.006,0.716-0.018
	C53.828,358.265,70.499,340.739,82.67,327.943z M455.236,303.299c9.971,10.481,18.597,19.55,41.764,21.102v20.786
	c-32.311-1.836-45.317-15.508-56.802-27.583c-6.185-6.501-11.663-12.26-20.231-16.037V279.57
	C437.419,284.57,446.813,294.446,455.236,303.299z M107.034,247.884c0-9.77,4.199-19.282,11.52-26.098
	c30.981-28.843,34.775-66.987,34.921-68.596c0.021-0.226,0.031-0.453,0.031-0.679V37.887h34.473V63.89
	c0,37.507,30.514,68.022,68.021,68.022s68.021-30.515,68.021-68.022V37.887h33.876v114.536c0,1.364,0.367,2.653,1.013,3.766
	c1.494,9.766,8.168,41.05,34.535,65.598c7.322,6.816,11.521,16.329,11.521,26.098v205.742c0,11.297-9.19,20.487-20.487,20.487
	H127.521c-11.297,0-20.487-9.19-20.487-20.487C107.034,453.627,107.034,247.884,107.034,247.884z M71.802,317.605
	C60.317,329.678,47.309,343.351,15,345.187v-20.786c23.167-1.551,31.792-10.619,41.765-21.102
	c8.422-8.854,17.816-18.729,35.269-23.729v21.997C83.467,305.344,77.988,311.103,71.802,317.605z"
      />
      <path
        d="M196.119,405.821h119.763c18.331,0,33.243-14.913,33.243-33.244v-85.938c0-4.142-3.358-7.5-7.5-7.5h-171.25
	c-4.142,0-7.5,3.358-7.5,7.5v85.938C162.875,390.908,177.788,405.821,196.119,405.821z M177.875,294.139h156.25v16.394h-23.659
	c-4.142,0-7.5,3.358-7.5,7.5s3.358,7.5,7.5,7.5h23.659v47.045c0,10.06-8.184,18.244-18.243,18.244H196.119
	c-10.06,0-18.244-8.184-18.244-18.244v-47.045h102.724c4.142,0,7.5-3.358,7.5-7.5s-3.358-7.5-7.5-7.5H177.875V294.139z"
      />
    </StyledSvg>
  );
};

export { LoadingIcon };
