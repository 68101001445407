/**
 * Used for Ant Design form's rule for custom function.
 * The form's value will be passed in like validateFn(value).
 * e.g.
 * const customRule = customAntdRuleValidator(validatorJs.isEmail, emailValue, "Bad Email!");
 * <Form.Item rules={[customRule]}
 * @param validateFn
 * @param value
 * @param errMessage
 */
export default function customAntdRuleValidator(validateFn, errMessage) {
  return form => ({
    validator: (rule, value) => {
      if (!value || validateFn(value, form)) {
        return Promise.resolve();
      }
      return Promise.reject(errMessage);
    },
  });
}
