import React from 'react';
import { Button, Typography } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useOrderModal } from '../../context/order';

const { Paragraph } = Typography;

const OrderSummaryItem = ({
  orderSummaryItem: {
    product,
    choices,
    totalFee,
    totalPrice,
    leftoverMaxPurchasableQuantity,
    quantity,
    comment,
  },
  orderItemIdx,
  setEditingIdx,
}) => {
  const { modifyOrderItem } = useOrderModal();

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        backgroundColor: '#fff',
        justifyContent: 'space-between',
      }}
    >
      <section style={{ display: 'flex', flexDirection: 'column' }}>
        <div>
          <strong style={{ fontSize: '16px', marginBottom: '8px', whiteSpace: 'pre-wrap' }}>
            {product.name}
          </strong>
          <span
            style={{
              marginLeft: '8px',
              fontSize: '16px',
              marginBottom: '8px',
              whiteSpace: 'pre-wrap',
            }}
          >
            x {quantity}
          </span>
          <Button
            type="link"
            onClick={() => setEditingIdx(orderItemIdx)}
            icon={<EditOutlined />}
          ></Button>
          <Button
            type="link"
            onClick={() => modifyOrderItem(orderItemIdx, { quantity: 0 })}
            icon={<DeleteOutlined />}
          ></Button>
        </div>
        <Paragraph
          style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word', margin: 0 }}
          type="secondary"
        >
          <ul style={{ margin: 0 }}>
            {choices.map(choice => (
              <li key={`choice-${choice.id}`}>
                {choice.name}{' '}
                {choice.listPriceAdjustment || choice.listPriceAdjustment > 0
                  ? `($${choice.listPriceAdjustment.toFixed(2)})`
                  : ''}
              </li>
            ))}
            {comment && (
              <li>
                <i>{comment}</i>
              </li>
            )}
          </ul>
        </Paragraph>
      </section>
      <section style={{ marginLeft: '16px', display: 'flex', flexDirection: 'column' }}>
        <strong
          style={{ fontSize: '16px', marginBottom: '8px', whiteSpace: 'pre-wrap', flex: '1' }}
        >
          ${totalPrice.toFixed(2)}
        </strong>
      </section>
    </div>
  );
};

export default OrderSummaryItem;
