/**
 * Route that redirects user to login page if the user is not signed in.
 * Route further redirects user to email confirmation page if the user's email has not been confirmed.
 *
 * @author Michael Yang
 *
 */

import React from 'react';
import { useAuth } from '../context/auth';
import { Redirect, Route, useHistory } from 'react-router-dom';
import { useLoginRegisterModal } from '../context/loginRegister';
import { Result } from 'antd';

const PrivateRoute = ({
  component: Component,
  path,
  admin = false,
  host = false,
  maintenance = false,
  ...rest
}) => {
  const { authenticatedUser, isAdmin, isHost } = useAuth();
  const { openLogin, opened } = useLoginRegisterModal();
  const history = useHistory();

  let render = props => <Component {...props} />;

  if (!authenticatedUser) {
    if (!opened) {
      openLogin(null, () => history.push('/'));
    }
    render = null;
  } else if (admin && !isAdmin) {
    render = props => <Redirect to="/" />;
  } else if (host && !isHost) {
    render = props => <Redirect to="/" />;
  } else if (maintenance) {
    render = prop => (
      <Result
        title="This page is currently under construction."
        subTitle="We apologize for the inconvenience. This page will be back in the near future!"
      />
    );
  }

  return <Route {...rest} path={path} render={render} />;
};

export default PrivateRoute;
