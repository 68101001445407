import { useMediaQuery } from 'react-responsive';

// same as ant design.
export const BREAKPOINT_WIDTH = {
  xs: '480px',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  xxl: '1600px',
};

// add more media queries as we go.
export const useIsSm = () => useMediaQuery({ query: `(max-width: ${BREAKPOINT_WIDTH.sm})` });
export const useIsMd = () => useMediaQuery({ query: `(max-width: ${BREAKPOINT_WIDTH.md})` });
export const useIsMobile = useIsMd;
export const useIsLg = () => useMediaQuery({ query: `(max-width: ${BREAKPOINT_WIDTH.lg})` });
export const useIsTablet = useIsLg;

export default useMediaQuery;
