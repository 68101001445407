import React from 'react';
import styled from 'styled-components';

export const DEFAULT_PADDING_TOP = 20;

const StyledContainer = styled.div`
  @media (max-width: 576px) {
    padding-left: 16px;
    padding-right: 16px;
    ${({ noPaddingBreakPoint }) =>
      ['xs', 'sm', 'md', 'lg', 'xl'].includes(noPaddingBreakPoint) && `padding: 0`}
  }

  @media (min-width: 576px) {
    ${({ noPaddingBreakPoint }) =>
      ['sm', 'md', 'lg', 'xl'].includes(noPaddingBreakPoint) && `padding: 0`}
  }

  @media (min-width: 768px) {
    ${({ noPaddingBreakPoint }) => ['md', 'lg', 'xl'].includes(noPaddingBreakPoint) && `padding: 0`}
  }

  @media (min-width: 992px) {
    max-width: 970px;
    ${({ noPaddingBreakPoint }) => ['lg', 'xl'].includes(noPaddingBreakPoint) && `padding: 0`}
  }

  @media (min-width: 1200px) {
    max-width: 1170px;
    ${({ noPaddingBreakPoint }) => ['xl'].includes(noPaddingBreakPoint) && `padding: 0`}
  }

  width: 100%;
  padding-left: 24px;
  padding-right: 24px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-direction: ${props => (props.horizontal ? 'row' : 'column')};
  padding-top: ${props => (props.horizontal ? '0' : `${DEFAULT_PADDING_TOP}px`)};
  align-items: ${props => (props.center ? 'center' : undefined)};
`;
const Container = ({ children, center, horizontal, noPaddingBreakPoint, ...rest }) => (
  <StyledContainer
    {...rest}
    center={center}
    horizontal={horizontal}
    noPaddingBreakPoint={noPaddingBreakPoint}
  >
    {children}
  </StyledContainer>
);

export default Container;
