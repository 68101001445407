import React, { useReducer, createContext, useContext } from 'react';
import jwtDecode from 'jwt-decode';
import ReactGA from 'react-ga';
import { useMutation } from '@apollo/react-hooks';
import { LOGOUT_USER } from '../utils/graphql';
import { setAccessToken } from './accessToken';
import { identify } from 'react-fullstory';

const initialState = {
  authenticatedUser: null,
};

const AuthContext = createContext({
  authenticatedUser: null,
  login: accessToken => {},
  logout: () => {},
});

function authReducer(state, action) {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        authenticatedUser: action.payload,
      };
    case 'LOGOUT':
      return {
        ...state,
        authenticatedUser: null,
      };
    default:
      return state;
  }
}

function AuthProvider(props) {
  const [state, dispatch] = useReducer(authReducer, initialState);
  const [logoutMutation] = useMutation(LOGOUT_USER);

  function login(accessToken) {
    setAccessToken(accessToken);
    dispatch({
      type: 'LOGIN',
      payload: jwtDecode(accessToken),
    });
  }

  function logout() {
    setAccessToken(null);
    logoutMutation();
    dispatch({ type: 'LOGOUT' });
  }

  if (state.authenticatedUser) {
    ReactGA.set({
      uuid: state.authenticatedUser.uuid,
      handle: state.authenticatedUser.handle,
    });

    identify(state.authenticatedUser.email, {
      uuid: state.authenticatedUser.uuid,
      handle: state.authenticatedUser.handle,
    });

    window.analytics.identify(state.authenticatedUser.uuid, {
      handle: state.authenticatedUser.handle,
      email: state.authenticatedUser.email,
    });
  }

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!state.authenticatedUser,
        authenticatedUser: state.authenticatedUser,
        login,
        logout,
        isAdmin: state.authenticatedUser && state.authenticatedUser.isSuperUser,
        isHost: state.authenticatedUser && state.authenticatedUser.isHost,
        isArukaraMember: state.authenticatedUser && state.authenticatedUser.isArukaraMember,
      }}
      {...props}
    />
  );
}

const useAuth = () => useContext(AuthContext);

export { AuthContext, AuthProvider, useAuth };
