import React from 'react';
import { Menu } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import ProfileSubMenu from './ProfileSubMenu';

const { SubMenu } = Menu;

const eventsMenu = (
  <Menu.Item key="events" style={{ borderBottom: '0px' }}>
    <Link to="/events">Events</Link>
  </Menu.Item>
);

const kitchenSubMenu = history => (
  <SubMenu
    key="kitchens"
    title="Kitchens"
    style={{ borderBottom: '0px' }}
    onTitleClick={() => history.push('/kitchens')}
  >
    <Menu.Item key="kitchens" style={{ borderBottom: '0px' }}>
      <Link to="/kitchens">Kitchens</Link>
    </Menu.Item>
    <Menu.Item key="list-your-kitchen" style={{ borderBottom: '0px' }}>
      <Link to="/list-your-kitchen">List Your Kitchen</Link>
    </Menu.Item>
  </SubMenu>
);

const hostSubMenu = history => (
  <SubMenu
    key="hosts"
    title="Hosts"
    style={{ borderBottom: '0px' }}
    onTitleClick={() => history.push('/hosts')}
  >
    <Menu.Item key="hosts" style={{ borderBottom: '0px' }}>
      <Link to="/hosts">Hosts</Link>
    </Menu.Item>
    <Menu.Item key="become-a-host" style={{ borderBottom: '0px' }}>
      <Link to="/become-a-host">Become a Host</Link>
    </Menu.Item>
  </SubMenu>
);

export const HorizontalMenu = props => {
  const history = useHistory();
  return (
    <Menu mode="horizontal" style={{ borderBottom: '0px', paddingRight: '10px' }}>
      {eventsMenu}
      {kitchenSubMenu(history)}
      {hostSubMenu(history)}
      <ProfileSubMenu key="profile" style={{ borderBottom: '0px', paddingRight: 0 }} {...props} />
    </Menu>
  );
};

export const DrawerMenu = ({ close, ...props }) => {
  const history = useHistory();
  return (
    <Menu
      mode="inline"
      style={{ borderBottom: '0px', paddingRight: 0 }}
      onClick={close}
      defaultOpenKeys={['kitchens', 'hosts']}
    >
      <ProfileSubMenu isDrawer key="profile" {...props} />
      <Menu.Divider />
      {eventsMenu}
      {kitchenSubMenu(history)}
      {hostSubMenu(history)}
    </Menu>
  );
};
