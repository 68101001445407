import React from 'react';
import { Modal, Typography } from 'antd';
import { useOrderModal } from '../../context/order';
import isNil from 'lodash.isnil';
import OrderItemForm from './OrderItemForm';

const { Text } = Typography;

export const OrderItemEditModal = ({ orderItemIdx, visible, close }) => {
  const { modifyOrderItem, orderItems, event } = useOrderModal();

  if (isNil(orderItemIdx) || orderItemIdx < 0 || orderItemIdx >= orderItems.length) {
    return null;
  }

  const orderItemToEdit = orderItems[orderItemIdx];

  const product = event.products.find(product => product.id === orderItemToEdit.productId);

  const onFormSubmit = orderItem => modifyOrderItem(orderItemIdx, orderItem);

  return (
    <ProductModal
      product={product}
      visible={visible}
      close={close}
      initialOrderItem={orderItemToEdit}
      onFormSubmit={onFormSubmit}
      formSubmitButtonText="Update Cart"
    />
  );
};

const ProductModal = ({
  product,
  visible,
  close,
  initialOrderItem,
  onFormSubmit,
  formSubmitButtonText,
}) => {
  const { addToOrder } = useOrderModal();
  if (!product) {
    return null;
  }

  onFormSubmit = onFormSubmit ? onFormSubmit : orderItem => addToOrder(orderItem);

  const onFinish = orderItem => {
    onFormSubmit(orderItem);
    close();
  };

  const bodyPaddingPx = 16;

  return (
    <Modal
      title={
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <strong style={{ fontSize: '16px', marginBottom: '8px', whiteSpace: 'pre-wrap' }}>
            {product.name}
          </strong>
          <Text type="secondary">${product.listPrice.toFixed(2)}</Text>
        </div>
      }
      visible={visible}
      footer={null}
      onCancel={close}
      bodyStyle={{
        padding: `12px ${bodyPaddingPx}px`,
      }}
      centered
    >
      <OrderItemForm
        onFinish={onFinish}
        product={product}
        initialOrderItem={initialOrderItem}
        formSubmitButtonText={formSubmitButtonText}
      />
    </Modal>
  );
};

export default ProductModal;
