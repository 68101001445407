import React from 'react';
import { Button } from 'antd';
import { useLoginRegisterModal } from '../../context/loginRegister';

const ForgotPasswordButton = ({ children, onFinish, ...props }) => {
  const { openForgotPassword } = useLoginRegisterModal();

  return (
    <Button onClick={() => openForgotPassword(onFinish)} {...props}>
      {children ? children : 'Reset Password'}
    </Button>
  );
};

export default ForgotPasswordButton;
