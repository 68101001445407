import React from 'react';
import { Typography, Tag, Button } from 'antd';
import styled from 'styled-components';
import ImageList from '../Common/ImageList';

const { Paragraph } = Typography;

const ResponsiveSection = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 776px) {
    flex-direction: column-reverse;
  }
`;

const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
`;

const Product = ({ product, onClick, disabled, isEventPast }) => (
  <div
    style={{
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#fff',
      padding: '16px',
      justifyContent: 'space-between',
      border: '1px solid #e2e2e2',
    }}
  >
    <ResponsiveSection>
      <section style={{ display: 'flex', flexDirection: 'column', marginRight: '8px' }}>
        <strong style={{ fontSize: '16px', marginBottom: '8px', whiteSpace: 'pre-wrap' }}>
          {product.name}
        </strong>
        <span style={{ marginBottom: '16px' }}>${product.listPrice.toFixed(2)}</span>

        <Paragraph
          style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word', lineHeight: '18px' }}
          type="secondary"
        >
          {product.description}
        </Paragraph>
      </section>
      <section style={{ marginBottom: '8px' }}>
        <ImageList imageUrls={[product.imgUrl]} maxPreview={1} />
      </section>
    </ResponsiveSection>
    {!isEventPast && (
      <FooterContainer>
        <Tag color="default" style={{ margin: 'auto 0' }}>
          {product.leftoverQuantity}/{product.totalQuantity} Remaining
        </Tag>
        <Button type="primary" onClick={onClick} disabled={disabled}>
          Add To Cart
        </Button>
      </FooterContainer>
    )}
  </div>
);

export default Product;
