import React, { useState } from 'react';
import { Upload, Modal, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

const noop = () => {};
const ImageUploader = ({ onChange = noop, maxFileCount = 10, style }) => {
  const [state, setState] = useState({
    previewVisible: false,
    previewImage: '',
    fileList: [],
  });
  const [generatePreSignedUploadUrl] = useMutation(GENERATE_PRESIGNED_URL_MUTATION);

  const onUpload = async file => {
    const {
      data: { generatePreSignedUploadUrlForImage: urls },
    } = await generatePreSignedUploadUrl({ variables: { contentType: file.type } });

    return urls;
  };

  const handleCancel = () => setState(prev => ({ ...prev, previewVisible: false }));

  const handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setState(prev => ({
      ...prev,
      previewImage: file.url || file.preview,
      previewVisible: true,
    }));
  };

  const handleChange = info => {
    let fileList = [...info.fileList];

    if (fileList.length > maxFileCount) {
      message.warning({
        content: `You can upload ${maxFileCount} images max.`,
        key: 'picturesWallMessage',
      });
    }

    fileList = fileList.slice(0, maxFileCount);

    onChange({ fileList, ...info });
    return setState(prev => ({ ...prev, fileList }));
  };

  const { previewVisible, previewImage, fileList } = state;

  const uploadFile = ({ file, action, onSuccess, onError, onProgress }) => {
    const xhr = new XMLHttpRequest();
    xhr.open('PUT', action.putUrl);
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          onSuccess({ getUrl: action.getUrl });
        } else {
          onError();
        }
      }
    };
    xhr.upload.onprogress = e => {
      if (e.lengthComputable) {
        const percentComplete = (e.loaded / file.size) * 100;
        onProgress({ percent: percentComplete });
      }
    };
    xhr.send(file);
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div>Upload Image</div>
    </div>
  );

  return (
    <>
      <Upload
        style={{ ...style }}
        accept="image/x-png,image/gif,image/jpeg"
        action={onUpload}
        customRequest={uploadFile}
        listType="picture-card"
        multiple={maxFileCount > 1}
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
      >
        {fileList.length >= maxFileCount ? null : uploadButton}
      </Upload>
      <Modal visible={previewVisible} footer={null} onCancel={handleCancel}>
        <img alt="example" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </>
  );
};

export const GENERATE_PRESIGNED_URL_MUTATION = gql`
  mutation generatePreSignedUploadUrlForImage($contentType: String!, $fileName: String) {
    generatePreSignedUploadUrlForImage(contentType: $contentType, fileName: $fileName) {
      putUrl
      getUrl
    }
  }
`;

export default ImageUploader;
