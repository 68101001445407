import React from 'react';
import { Link } from 'react-router-dom';
import OurStoryImage from '../images/our-story.jpg';
import cutleryIcon from '../images/icons/cutlery.svg';
import chefIcon from '../images/icons/chef.svg';
import shopIcon from '../images/icons/shop.svg';
import ramen from '../images/ramen.svg';
import chef from '../images/chef.svg';
import oven from '../images/oven.svg';
import './Landing.css';
import Container from '../components/Common/Container';

const Landing = () => (
  <Container style={{ paddingTop: 0 }} className="landing">
    <div className="container">
      <div className="top-box-panels">
        <div
          className="top-box-panel"
          style={{
            backgroundColor: '#ffecb8',
            animationDelay: '0.1s',
          }}
        >
          <div
            className="top-box-content"
            style={{
              animationDelay: '0.8s',
            }}
          >
            <img src={ramen} alt="Find Events" />
            <Link className="top-button button-bottom" to="/events">
              FIND EVENTS
            </Link>
          </div>
        </div>
        <div
          className="top-box-panel"
          style={{
            backgroundColor: '#f6a6b2',
            animationDelay: '0.3s',
          }}
        >
          <div
            className="top-box-content"
            style={{
              animationDelay: '1s',
            }}
          >
            <img src={chef} alt="Host Events" />
            <Link className="top-button button-bottom" to="/become-a-host">
              HOST EVENTS
            </Link>
          </div>
        </div>
        <div
          className="top-box-panel"
          style={{
            backgroundColor: '#b7ded2',
            animationDelay: '0.5s',
          }}
        >
          <div
            className="top-box-content"
            style={{
              animationDelay: '1.2s',
            }}
          >
            <img src={oven} alt="Provide Kitchen" />
            <Link className="top-button button-bottom" to="/list-your-kitchen">
              PROVIDE KITCHEN
            </Link>
          </div>
        </div>
      </div>
      <div className="middle-box">
        <div className="vision-text-box">
          <h1>Our Story</h1>
          <p>
            Following his childhood dream of becoming a chef, Michael began hosting his own pop-ups
            at Yume Wo Katare. This grew into an idea to support anyone, from home cooks to
            professional chefs, to host their own pop-ups at local restaurants.
          </p>
          <Link className="middle-button" to="/about-us">
            READ MORE
          </Link>
        </div>
      </div>
      <div className="bottom-box">
        <div className="mission-text-box">
          <h1>Our Mission</h1>
          <div className="for-info-flex-container">
            <div className="for-info-container">
              <img src={cutleryIcon} alt="Cutlery Icon" />
              <h2>For Eaters</h2>
              <p>Find unique food experiences and support local chefs.</p>
            </div>
            <div className="for-info-container">
              <img src={chefIcon} alt="Chef Icon" />
              <h2>For Chefs</h2>
              <p>Build your brand as a chef and find restaurants to work with.</p>
            </div>
            <div className="for-info-container">
              <img src={shopIcon} alt="Shop Icon" />
              <h2>For Restaurants</h2>
              <p>Diversify your revenue and collaborate with talented chefs.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Container>
);

export default Landing;
