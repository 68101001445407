import get from 'lodash.get';
import { StringParam, useQueryParam } from 'use-query-params';
import { useHistory } from 'react-router-dom';

const useRedirectTo = () => {
  const history = useHistory();
  const { pathname = '/', search = '' } = get(history, 'location.state.from', {});

  const [redirectToQs] = useQueryParam('redirectTo', StringParam);

  return redirectToQs || pathname + search;
};

export default useRedirectTo;
