import React from 'react';
import { Helmet } from 'react-helmet';

const CustomHelmet = ({
  type = 'website',
  title = 'Souskasa - Discover Local Pop-up Events. Pop-up solution for Chefs. Airbnb for Restaurants.',
  description = 'Souskasa is a platform where eaters can find local pop up events. We also provide an Airbnb solution for restaurants and pop-up solution for chefs.',
  image = 'https://www.souskasa.com/static/media/logo.3ecd419c.svg',
  children,
}) => {
  return (
    <Helmet>
      <title>{title || 'Souskasa'}</title>
      <meta name="title" content={title} />
      <meta name="description" content={description} />

      {/*Facebook*/}
      <meta name="og:type" content={type} />
      <meta name="og:url" content={window.location.href} />
      <meta name="og:title" content={title} />
      <meta name="og:description" content={description} />
      <meta name="og:image" content={image} />

      {/*Twitter*/}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:url" content={window.location.href} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
      {/*wrap around another helmet to have children override the above*/}
      <Helmet>{children}</Helmet>
    </Helmet>
  );
};

export default CustomHelmet;
