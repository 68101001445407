import React, { useState } from 'react';
import { HorizontalMenu, DrawerMenu } from './Menu';
import logo from '../../images/souskasa-logo.svg';
import { Link } from 'react-router-dom';
import { Drawer, Button } from 'antd';
import Container from '../Common/Container';
import { MenuOutlined } from '@ant-design/icons';
import { useIsMobile } from '../../hooks/useMediaQuery';

const Navbar = () => {
  const [visible, setVisible] = useState(false);
  const isMobile = useIsMobile();

  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  return (
    <nav style={{ width: '100%' }}>
      <Container horizontal style={{ alignItems: 'center', width: '100%' }}>
        <Link to="/">
          <img src={logo} alt="Logo" height={45} />
        </Link>
        {isMobile ? (
          <Container
            horizontal
            style={{ justifyContent: 'flex-end', alignItems: 'center', padding: 0 }}
          >
            <Button type="link" onClick={showDrawer}>
              <MenuOutlined style={{ fontSize: '24px' }} />
            </Button>
            <Drawer
              placement="right"
              closable={false}
              onClose={onClose}
              visible={visible}
              bodyStyle={{ padding: '10px 0 0 0' }}
            >
              <DrawerMenu close={onClose} />
            </Drawer>
          </Container>
        ) : (
          <Container
            horizontal
            style={{ justifyContent: 'flex-end', alignItems: 'center', padding: 0 }}
          >
            <HorizontalMenu />
          </Container>
        )}
      </Container>
    </nav>
  );
};
export default Navbar;
