import React, { useEffect, useState } from 'react';
import { formatDate, getISODate } from '../../utils/timeFunction';
import TimeAgo from 'react-timeago';

const Countdown = ({ to, onComplete = () => {}, timezone = 'America/New_York' }) => {
  const [left, setLeft] = useState(new Date(to) - new Date());
  useEffect(() => {
    const interval = setTimeout(() => {
      const timeLeft = new Date(to) - new Date();

      // if difference is within 1 second passed, countdown is completed.
      if (timeLeft <= 0) {
        onComplete();
      } else {
        setLeft(timeLeft);
      }
    }, 1000);
    return () => {
      clearTimeout(interval);
    };
  }, [to, onComplete, left, setLeft]);

  return (
    <TimeAgo
      date={getISODate(to, timezone)}
      formatter={(value, unit, text) => {
        if (
          (unit === 'hour' && value >= 12) ||
          unit === 'day' ||
          unit === 'week' ||
          unit === 'year' ||
          unit === 'month'
        )
          return formatDate(to, 'llll z', timezone);

        return `${value} ${unit}${value > 1 ? 's' : ''} ${text}`;
      }}
    />
  );
};

export default Countdown;
