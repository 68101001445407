import React from 'react';
import { Avatar, Typography } from 'antd';
import { UserOutlined, HeartFilled } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const { Text } = Typography;

const HostCardHorizontalContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 12px;
`;

const Stats = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const HostCardHorizontal = ({ host }) => (
  <HostCardHorizontalContainer>
    <DetailsContainer>
      <Link to={`/hosts/${host.slug}`}>{host.displayName}</Link>
      <Stats>
        <HeartFilled style={{ color: '#FF6F61', marginRight: '4px' }} />
        {(host.stats?.averageRating && host.stats?.reviewCount && (
          <Text style={{ whiteSpace: 'nowrap' }}>
            {host.stats?.averageRating.toFixed(1)} ({host.stats?.reviewCount})
          </Text>
        )) || <Text style={{ whiteSpace: 'nowrap', fontSize: '12px' }}>No Rating</Text>}
      </Stats>
    </DetailsContainer>
    {host.profileImgUrl ? (
      <Avatar src={host.profileImgUrl} size="large" />
    ) : (
      <Avatar icon={<UserOutlined />} size="large" />
    )}
  </HostCardHorizontalContainer>
);

export default HostCardHorizontal;
