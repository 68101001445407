import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';
import { AuthProvider } from './context/auth';
import AuthRoute from './utils/AuthRoute';
import PrivateRoute from './utils/PrivateRoute';
import ScrollToTop from './utils/ScrollToTop';
import Shell from './components/Shell';
import Loading from './components/Loading';
import './App.css';
import { QueryParamProvider } from 'use-query-params';
import FullStory from 'react-fullstory';
import CustomHelmet from './components/Common/CustomHelmet';
import 'moment-timezone';
import { LoginRegisterProvider } from './context/loginRegister';
import { OrderProvider } from './context/order';
import Test from './components/Admin/new-edit/Test';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from './components/ErrorFallback';
import smoothscroll from 'smoothscroll-polyfill';

import Landing from './pages/Landing';
const HostManagement = lazy(() => import('./pages/HostManagement'));
const NotFoundPage = lazy(() => import('./pages/NotFoundPage'));
const Login = lazy(() => import('./pages/Login'));
const User = lazy(() => import('./pages/User'));
const Kitchens = lazy(() => import('./pages/Kitchens'));
const Kitchen = lazy(() => import('./pages/Kitchen'));
const Hosts = lazy(() => import('./pages/Hosts'));
const Host = lazy(() => import('./pages/Host'));
const Events = lazy(() => import('./pages/Events'));
const Event = lazy(() => import('./pages/Event'));
const Register = lazy(() => import('./pages/Register'));
const AboutUs = lazy(() => import('./pages/AboutUs'));
const ListYourKitchen = lazy(() => import('./pages/ListYourKitchen'));
const BecomeAHost = lazy(() => import('./pages/BecomeAHost'));
const Tickets = lazy(() => import('./pages/Tickets'));
const Scan = lazy(() => import('./pages/Scan'));
const Review = lazy(() => import('./pages/Review'));
const Terms = lazy(() => import('./pages/Terms'));
const ContactUs = lazy(() => import('./pages/ContactUs'));
const SendEmailConfirmation = lazy(() => import('./pages/SendEmailConfirmation'));
const SendPasswordResetEmail = lazy(() => import('./pages/SendPasswordResetEmail'));
const ResetPassword = lazy(() => import('./pages/ResetPassword'));
const ConfirmEmail = lazy(() => import('./pages/ConfirmEmail'));
const Admin = lazy(() => import('./pages/Admin'));

ReactGA.initialize('UA-148538787-1');
const history = createBrowserHistory();
smoothscroll.polyfill();

// Initialize google analytics page view tracking
history.listen(location => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

let prevPath = null;

history.listen(location => {
  if (location.pathname !== prevPath) {
    prevPath = location.pathname;
    window.analytics.page();
  }
});

function App() {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <AuthProvider>
        <FullStory org="SEX77" />
        <Router>
          <LoginRegisterProvider>
            <CustomHelmet />
            <QueryParamProvider ReactRouterRoute={Route}>
              <ScrollToTop />
              <Shell>
                <Test />
                <Suspense fallback={<Loading />}>
                  <Switch>
                    {/* Misc */}
                    <Route exact path="/" component={Landing} />
                    <Route exact path="/about-us" component={AboutUs} />
                    <Route exact path="/contact-us" component={ContactUs} />
                    <Route exact path="/terms" component={Terms} />
                    <Route exact path="/list-your-kitchen" component={ListYourKitchen} />
                    <Route exact path="/become-a-host" component={BecomeAHost} />

                    {/* Authentication Flow */}
                    <AuthRoute exact path="/login" component={Login} />
                    <AuthRoute exact path="/login/password" component={SendPasswordResetEmail} />
                    <AuthRoute exact path="/login/password/:token" component={ResetPassword} />
                    <AuthRoute exact path="/register" component={Register} />
                    <PrivateRoute
                      exact
                      path="/email-confirmation"
                      component={SendEmailConfirmation}
                    />
                    <Route path="/email-confirmation/:token" component={ConfirmEmail} />

                    {/* User */}
                    {/* Order for below two rows matter */}
                    <Route path="/users/:handle/:tab" component={User} />
                    <Route path="/users/:handle" component={User} />

                    {/* Host */}
                    <Route exact path="/hosts" component={Hosts} />
                    {/* Order for below two rows matter */}
                    <Route path="/hosts/:slug/:tab" component={Host} />
                    <Route path="/hosts/:slug" component={Host} />

                    {/* Kitchen */}
                    <Route exact path="/kitchens" component={Kitchens} />
                    <Route path="/kitchens/:slug" component={Kitchen} />

                    {/* Host Management*/}
                    <PrivateRoute path="/manage" component={HostManagement} host />
                    <PrivateRoute exact path="/scan" component={Scan} host />

                    {/* Event */}
                    <Route exact path="/events" component={Events} />
                    <Route
                      path="/events/:eventId"
                      render={routeProps => (
                        <OrderProvider>
                          <Event {...routeProps} />
                        </OrderProvider>
                      )}
                    />

                    {/* Ticket */}
                    <PrivateRoute exact path="/tickets" component={Tickets} />

                    {/* Review */}
                    <Route path="/attendances/:reviewId" component={Review} />

                    {/* Admin */}
                    <PrivateRoute exact path="/showmethemoney" component={Admin} admin />
                    <PrivateRoute path="/admin" component={Admin} admin />

                    {/* 404 */}
                    <Route path="*" component={NotFoundPage} />
                  </Switch>
                </Suspense>
              </Shell>
            </QueryParamProvider>
          </LoginRegisterProvider>
        </Router>
      </AuthProvider>
    </ErrorBoundary>
  );
}

export default App;
