import React, { useState } from 'react';
import { LockOutlined, MailOutlined, UserOutlined } from '@ant-design/icons';
import { Input, Button, Form, notification } from 'antd';
import { useAuth } from '../../context/auth';
import { useMutation } from '@apollo/react-hooks';
import validatorJs from 'validator';

import { REGISTER_USER } from '../../utils/graphql';
import { ReactComponent as AtSign } from '../../images/icons/at-sign.svg';
import { PasswordInput } from 'antd-password-input-strength';
import get from 'lodash.get';
import customAntdRuleValidator from '../../utils/customAntdRuleValidator';
import { useLoginRegisterModal } from '../../context/loginRegister';
import LoginButton from './LoginButton';

const RegisterForm = ({ onFinish = () => {} }) => {
  const context = useAuth();
  const [errors, setErrors] = useState({});
  const { close } = useLoginRegisterModal();

  const [register, { loading }] = useMutation(REGISTER_USER, {
    update: (_, { data: { register: response } }) => {
      context.login(response.accessToken);
      close();
      notification.success({
        message: 'Welcome!',
        description:
          'A confirmation email was sent! Please confirm in order to enjoy the full Souskasa experience.',
      });
      onFinish();
    },
    onError(err) {
      setErrors(
        get(err, 'graphQLErrors[0].extensions.exception.errors', {
          general: 'Registration failed unexpectedly.',
        })
      );
    },
  });

  const onSubmit = values => register({ variables: { ...values } });

  return (
    <Form onFinish={onSubmit} layout="vertical">
      <Form.Item
        name="firstName"
        label="First Name"
        rules={[{ required: true, message: 'This is required.' }]}
        {...(errors.firstName && {
          help: errors.firstName,
          validateStatus: 'error',
        })}
        style={{
          display: 'inline-block',
          width: 'calc(50% - 12px)',
        }}
      >
        <Input prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Mike" />
      </Form.Item>

      <span
        style={{
          display: 'inline-block',
          width: '24px',
        }}
      />
      <Form.Item
        name="lastName"
        label="Last Name"
        rules={[{ required: true, message: 'This is required.' }]}
        {...(errors.lastName && {
          help: errors.lastName,
          validateStatus: 'error',
        })}
        style={{
          display: 'inline-block',
          width: 'calc(50% - 12px)',
        }}
      >
        <Input
          prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
          placeholder="Wazowski"
        />
      </Form.Item>
      <Form.Item
        name="email"
        label="Email"
        normalize={value => (value || '').trim().toLowerCase()}
        rules={[
          { required: true, message: 'This is required.' },
          customAntdRuleValidator(val => validatorJs.isEmail(val), 'Please provide a valid email.'),
        ]}
        {...(errors.email && {
          help: errors.email,
          validateStatus: 'error',
        })}
      >
        <Input
          prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
          placeholder="johndoe@gmail.com"
        />
      </Form.Item>
      <Form.Item
        name="password"
        label="Password"
        rules={[
          { required: true, message: 'This is required.' },
          customAntdRuleValidator(
            val => validatorJs.isLength(val, 6),
            'Password should be longer than 6 characters.'
          ),
        ]}
        {...(errors.password && {
          help: errors.password,
          validateStatus: 'error',
        })}
      >
        <PasswordInput prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} />
      </Form.Item>
      <Form.Item
        name="handle"
        label="Handle"
        normalize={value => (value || '').trim().toLowerCase()}
        rules={[
          { required: true, message: 'This is required.' },
          customAntdRuleValidator(
            val => validatorJs.isLength(val, 3, 16),
            'Should be 3-16 characters.'
          ),
          customAntdRuleValidator(
            val => validatorJs.isAlphanumeric(val),
            'Should be alphanumeric.'
          ),
          customAntdRuleValidator(val => validatorJs.isLowercase(val), 'Should be lowercase.'),
        ]}
        {...(errors.handle && {
          help: errors.handle,
          validateStatus: 'error',
        })}
      >
        <Input prefix={<AtSign style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="johndoe" />
      </Form.Item>
      <Form.Item
        {...(errors.general && {
          help: <b>{errors.general}</b>,
          validateStatus: 'error',
        })}
      >
        <Button
          type="primary"
          htmlType="submit"
          block
          loading={loading}
          style={{ marginBottom: '20px' }}
        >
          Create My Account
        </Button>
        Already have an account?{' '}
        <LoginButton type="link" style={{ padding: 0 }} onFinish={onFinish}>
          Sign in here
        </LoginButton>
        .
      </Form.Item>
    </Form>
  );
};

export default RegisterForm;
