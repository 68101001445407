import React, { useState } from 'react';
import { Col, Row } from 'antd';
import ProductModal from './ProductModal';
import Product from './Product';
import moment from 'moment';
import useHistoryModalState from '../../hooks/useHistoryModalState';

const Products = ({ products, event }) => {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const { open: openModal, close: closeModal, opened } = useHistoryModalState();

  const { endAt, onSaleAt, endSaleAt, published } = event;

  const leftoverQuantities = products.reduce((sum, product) => product.leftoverQuantity + sum, 0);

  const soldOut = leftoverQuantities === 0;

  const isEventPast = moment(endAt).isBefore();
  const isSaleUpcoming = onSaleAt && moment(onSaleAt).isAfter();
  const isSalePast = endSaleAt && moment(endSaleAt).isBefore();

  const eventDisabled = soldOut || isEventPast || !published || isSaleUpcoming || isSalePast;

  const close = () => {
    closeModal();
  };

  return (
    <>
      <Row gutter={[16, 16]}>
        {products.map(product => {
          return (
            <Col key={product.id} xs={24} sm={12}>
              <Product
                product={product}
                disabled={eventDisabled}
                isEventPast={isEventPast}
                onClick={
                  eventDisabled
                    ? null
                    : () => {
                        setSelectedProduct(product);
                        openModal();
                      }
                }
              />
            </Col>
          );
        })}
      </Row>
      <ProductModal product={selectedProduct} visible={opened} close={close} />
    </>
  );
};

export default Products;
