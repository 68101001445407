import React, { useState } from 'react';
import { Button, Card, Col, Modal, Row } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import Image, { ASPECT_RATIO } from './Image';
import useHistoryModalState from '../../hooks/useHistoryModalState';

const ImageList = ({
  imageUrls = [],
  maxPreview = 3,
  numberOfPreviewColumns = maxPreview,
  hideViewMoreOverlay = false,
  previewImageProps,
  style,
  imageContainerStyle,
}) => {
  const { open, close, opened } = useHistoryModalState();

  const [currentImageIdx, setCurrentImageIdx] = useState(0);

  if (!imageUrls || !imageUrls.length) {
    return null;
  }

  const setNextPreview = () => setCurrentImageIdx((currentImageIdx + 1) % imageUrls.length);
  const setPrevPreview = () =>
    setCurrentImageIdx(currentImageIdx === 0 ? imageUrls.length - 1 : currentImageIdx - 1);

  const previewUrls = maxPreview ? imageUrls.slice(0, maxPreview) : imageUrls;

  return (
    <div style={{ width: '100%', ...style }}>
      <Row gutter={maxPreview === 1 ? undefined : [8, 8]}>
        {previewUrls.map((imgUrl, idx) => {
          const lastPreview = imageUrls.length > maxPreview && idx + 1 === maxPreview;

          return (
            <Col span={24 / numberOfPreviewColumns} key={imgUrl + idx}>
              <Card
                hoverable
                bordered={false}
                cover={
                  <Image src={imgUrl} {...previewImageProps} containerStyle={imageContainerStyle} />
                }
                onClick={() => {
                  open();
                  setCurrentImageIdx(idx);
                }}
                bodyStyle={{ padding: 0 }}
              />
              {lastPreview && !hideViewMoreOverlay && (
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    margin: '4px',
                    background: 'rgba(0,0,0,0.4)',
                    color: '#fff',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    pointerEvents: 'none',
                  }}
                >
                  View {imageUrls.length - maxPreview} More
                </div>
              )}
            </Col>
          );
        })}
      </Row>
      <Modal
        visible={opened}
        footer={null}
        centered
        width="auto"
        onCancel={close}
        className="no-full-screen"
        style={{ paddingBottom: 0, maxWidth: '80vw', minWidth: '80vw' }}
        bodyStyle={{
          padding: 0,
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            height: '100vh',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Image
            src={imageUrls.length && imageUrls[currentImageIdx]}
            aspectRatio={ASPECT_RATIO.auto}
            useIntrinsicDimensions
            fit="contain"
          />
          <div style={{ position: 'absolute', top: '50%', left: '0' }}>
            {imageUrls.length > 1 && (
              <Button
                style={{
                  background: 'rgba(255, 255, 255, 0.3)',
                  border: 'none',
                }}
                disabled={imageUrls.length <= 1}
                icon={
                  <LeftOutlined
                    style={{
                      fontSize: '24px',
                    }}
                  />
                }
                onClick={setPrevPreview}
              />
            )}
          </div>
          <div style={{ position: 'absolute', top: '50%', right: '0' }}>
            {imageUrls.length > 1 && (
              <Button
                style={{
                  background: 'rgba(255, 255, 255, 0.3)',
                  border: 'none',
                }}
                disabled={imageUrls.length <= 1}
                icon={
                  <RightOutlined
                    style={{
                      fontSize: '24px',
                    }}
                  />
                }
                onClick={setNextPreview}
              />
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ImageList;
