import React from 'react';
import { Button } from 'antd';
import { useLoginRegisterModal } from '../../context/loginRegister';

const LoginButton = ({ children, onFinish, ...props }) => {
  const { openLogin } = useLoginRegisterModal();

  return (
    <Button onClick={() => openLogin(onFinish)} {...props}>
      {children ? children : 'Login'}
    </Button>
  );
};

export default LoginButton;
