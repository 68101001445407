import React, { useState, useContext, useEffect } from 'react';
import OrderModal from '../components/Event/OrderModal';
import { bankersRound } from 'bankers-round';
import sumBy from 'lodash.sumby';
import flatMap from 'lodash.flatmap';
import { useLazyQuery } from '@apollo/react-hooks';
import { FETCH_EVENT_QUERY } from '../pages/Event';
import { useAuth } from './auth';
import useHistoryModalState from '../hooks/useHistoryModalState';

const OrderContext = React.createContext(null);

const createOrderSummary = (event, orderItems, getArukaraDiscount, tip) => {
  if (!orderItems) {
    return null;
  }
  const hydratedOrderItems = orderItems.map(({ productId, options, comment, quantity }) => {
    const product = event.products.find(product => product.id === productId);
    const productOptionChoices = !product.options
      ? []
      : flatMap(product.options, option => option.choices || []);
    const selectedChoiceIds = flatMap(options, option =>
      option.choices.map(choice => choice.optionChoiceId)
    );
    const selectedChoices = selectedChoiceIds.map(choiceId =>
      productOptionChoices.find(optionChoice => optionChoice.id === choiceId)
    );

    const totalUnitPriceAdjustment = selectedChoices.reduce(
      (sum, choice) => sum + (choice.listPriceAdjustment ? choice.listPriceAdjustment : 0),
      0
    );

    const leftoverMaxPurchasableQuantity = Math.min(
      product.leftoverQuantity,
      product.maxPurchasableQuantity
    );

    // EXACT SAME CALCULATION AS SERVER.
    const totalUnitPrice = Math.max(0, product.listPrice + totalUnitPriceAdjustment);
    const unitFee = (product.taxRate * totalUnitPrice) / 100;
    // before tax.
    const totalPrice = totalUnitPrice * quantity;
    const totalFee = unitFee * quantity;
    const total = bankersRound(totalPrice + totalFee, 2);

    if (getArukaraDiscount) {
      const totalAdjustmentsFromOptions =
        sumBy(selectedChoices, choice =>
          choice.freeForArukaraMember ? 0 : choice.listPriceAdjustment || 0
        ) || 0;

      const unitPrice = product.freeForArukaraMember ? 0 : product.listPrice;
      const unitPriceAdjustment = Math.max(0, totalAdjustmentsFromOptions);
      const totalUnitPrice = unitPrice + unitPriceAdjustment;
      const unitFee = (product.taxRate * totalUnitPrice) / 100;
      const totalPrice = totalUnitPrice * quantity;
      const totalFee = unitFee * quantity;
      const total = bankersRound(totalPrice + totalFee, 2);

      return {
        product,
        choices: selectedChoices,
        leftoverMaxPurchasableQuantity,
        totalFee,
        totalPrice,
        total,
        quantity,
        comment,
      };
    }

    return {
      product,
      choices: selectedChoices,
      leftoverMaxPurchasableQuantity,
      totalFee,
      totalPrice,
      total,
      quantity,
      comment,
    };
  });

  const orderItemsTotal = hydratedOrderItems.reduce((currTotal, orderItem) => {
    return currTotal + orderItem.total;
  }, 0);
  const roundedOrderItemsTotal = bankersRound(orderItemsTotal, 2);

  const orderTotal = roundedOrderItemsTotal + (tip || 0);

  const subTotal = hydratedOrderItems.reduce((sum, orderItem) => sum + orderItem.totalPrice, 0);
  const fees = hydratedOrderItems.reduce((sum, orderItem) => sum + orderItem.totalFee, 0);

  return {
    orderItems: hydratedOrderItems,
    subTotal,
    fees,
    total: orderTotal,
    tip,
  };
};

const OrderProvider = ({ children, ...props }) => {
  const { open, close, opened } = useHistoryModalState();
  const [orderItems, setOrderItems] = useState([]);
  const [tip, setTip] = useState();
  const [eventId, setEventId] = useState(null);
  const [forceNormalUser, setForceNormalUser] = useState(false);
  const { isArukaraMember } = useAuth();

  const [refetch, { loading: eventLoading, data = {} }] = useLazyQuery(FETCH_EVENT_QUERY, {
    variables: {
      eventId,
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (eventId) {
      refetch();
    }
  }, [refetch, eventId]);

  const { eventById: event } = data;

  useEffect(() => {
    // if event is loaded and isn't an arukara event, they should be buying as normal users.
    if (event && event.host.id !== '3') {
      setForceNormalUser(true);
    }
  }, [event]);

  const clearOrderItems = () => {
    setOrderItems([]);
  };

  const modifyOrderItem = (orderItemIdx, modifiedOrderItem) => {
    if (orderItemIdx < 0 || orderItemIdx >= orderItems.length) {
      return;
    }

    let newOrderItems;

    const elementsBefore = orderItems.slice(0, orderItemIdx);
    const elementsAfter = orderItems.slice(orderItemIdx + 1, orderItems.length);

    const { quantity } = modifiedOrderItem;
    // implies deletion of the order item
    if (quantity === 0) {
      newOrderItems = [...elementsBefore, ...elementsAfter];
    } else {
      const newOrderItem = { ...orderItems[orderItemIdx], ...modifiedOrderItem };
      newOrderItems = [...elementsBefore, newOrderItem, ...elementsAfter];
    }

    setOrderItems(newOrderItems);
  };

  const addToOrder = orderItem => {
    setOrderItems([...orderItems, orderItem]);
  };

  // make sure this is arukara and show it for arukara events only.
  const isArukaraEvent = event && event.host.id === '3';
  const getArukaraDiscount = isArukaraEvent && !forceNormalUser && isArukaraMember;
  const getOrderSummary = () => createOrderSummary(event, orderItems, getArukaraDiscount, tip);

  return (
    <OrderContext.Provider
      value={{
        open,
        close,
        refetch,
        event,
        eventLoading,
        orderItems,
        addToOrder,
        clearOrderItems,
        setEventId,
        getOrderSummary,
        modifyOrderItem,
      }}
      {...props}
    >
      {event && (
        <OrderModal
          opened={opened}
          close={close}
          clearOrderItems={clearOrderItems}
          orderItems={orderItems}
          event={event}
          getOrderSummary={getOrderSummary}
          modifyOrderItem={modifyOrderItem}
          refetch={refetch}
          isArukaraMember={isArukaraMember}
          isArukaraEvent={isArukaraEvent}
          forceNormalUser={forceNormalUser}
          setForceNormalUser={setForceNormalUser}
          tip={tip}
          setTip={setTip}
          centered
        />
      )}
      {children}
    </OrderContext.Provider>
  );
};

const useOrderModal = () => useContext(OrderContext);

export { OrderContext, OrderProvider, useOrderModal };
