import gql from 'graphql-tag';

export const eventFragment = gql`
  fragment EventFragment on Event {
    id
    name
    mainImgUrl
    description
    startAt
    endAt
    onSaleAt
    endSaleAt
    published
    isActive
    isPast
    isUpcoming
    isOnSale
    tipEnabled
    tipNotes
    timezone
  }
`;

export const locationFragment = gql`
  fragment LocationFragment on Location {
    name
    googleMapLink
    fullAddress
  }
`;

export const hostFragment = gql`
  fragment HostFragment on Host {
    id
    uuid
    slug
    displayName
    profileImgUrl
    description
    facebookLink
    instagramLink
  }
`;

export const productFragment = gql`
  fragment ProductFragment on Product {
    id
    name
    imgUrl
    listPrice
    description
    taxRate
    minPurchasableQuantity
    maxPurchasableQuantity
    soldQuantity
    leftoverQuantity
    totalQuantity
    type
    freeForArukaraMember
    acceptComment
    countTowardsReservationLimit
  }
`;

export const cheerFragment = gql`
  fragment CheerFragment on Cheer {
    id
    eventId
    rawUrl
    imageUrl
  }
`;

export const productOptionFragment = gql`
  fragment ProductOptionFragment on ProductOption {
    id
    name
    description
    isRadio
    isRequired
    minChoiceCount
    maxChoiceCount
    choices {
      id
      name
      description
      listPriceAdjustment
      freeForArukaraMember
    }
  }
`;

export const eventReservationFragment = gql`
  fragment EventReservationFragment on EventReservation {
    id
    reserveAt
    leftoverQuantity
    totalQuantity
    absoluteTotalQuantity
    soldQuantity
  }
`;

export const userPublicFragment = gql`
  fragment UserPublicFragment on User {
    uuid
    firstName
    handle
    profileImgUrl
  }
`;

export const hostStatsFragment = gql`
  fragment HostStatsFragment on HostStats {
    reviewCount
    averageRating
    followerCount
  }
`;

export const orderFragment = gql`
  fragment OrderFragment on Order {
    id
    createdAt
    comment
    reservedAt
    hostConfirmed
    usedArukaraDiscount
  }
`;

export const reviewFragment = gql`
  fragment ReviewFragment on Review {
    id
    comment
    rating
    modifiedAt
    reviewImages {
      imgUrl
    }
  }
`;

export const LOGIN_USER = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      accessToken
    }
  }
`;

export const LOGOUT_USER = gql`
  mutation logout {
    logout
  }
`;

export const REGISTER_USER = gql`
  mutation register(
    $email: String!
    $handle: String!
    $firstName: String!
    $lastName: String!
    $password: String!
  ) {
    register(
      email: $email
      handle: $handle
      firstName: $firstName
      lastName: $lastName
      password: $password
    ) {
      accessToken
    }
  }
`;

export const SEND_PASSWORD_RESET_EMAIL = gql`
  mutation sendPasswordResetEmail($email: String!, $redirectTo: String!) {
    sendPasswordResetEmail(email: $email, redirectTo: $redirectTo)
  }
`;

export const RESET_PASSWORD = gql`
  mutation resetPassword($token: String!, $password: String!) {
    resetPassword(token: $token, password: $password) {
      accessToken
    }
  }
`;

export const SEND_CONFIRMATION_EMAIL = gql`
  mutation sendConfirmationEmail($redirectTo: String!) {
    sendConfirmationEmail(redirectTo: $redirectTo)
  }
`;

export const CONFIRM_EMAIL = gql`
  mutation confirmEmail($token: String!) {
    confirmEmail(token: $token) {
      accessToken
    }
  }
`;

export const FETCH_TICKETS_QUERY = gql`
  query getTickets {
    getTickets {
      uuid
      user {
        uuid
      }
      qrCode
      orderItem {
        id
        order {
          id
          createdAt
          reservedAt
          comment
        }
        product {
          name
          description
          imgUrl
          event {
            ...EventFragment
            location {
              ...LocationFragment
            }
            host {
              ...HostFragment
            }
          }
        }
      }
      activeAt
      expiresAt
      redeemedAt
    }
  }
  ${eventFragment}
  ${locationFragment}
  ${hostFragment}
`;

export const USE_TICKET = gql`
  mutation useTicket($qrCode: String!) {
    useTicket(qrCode: $qrCode) {
      uuid
      redeemedAt
    }
  }
`;

export const REDEEM_ORDER = gql`
  mutation redeemOrder($orderId: Float!) {
    redeemOrder(orderId: $orderId) {
      id
      redeemedAt
    }
  }
`;

export const UNREDEEM_ORDER = gql`
  mutation unredeemOrder($orderId: Float!) {
    unredeemOrder(orderId: $orderId) {
      id
      redeemedAt
    }
  }
`;

export const SET_ORDER_CONFIRM_STATUS = gql`
  mutation setOrderConfirmStatus($orderId: Float!, $status: Boolean!) {
    setOrderConfirmStatus(orderId: $orderId, status: $status) {
      id
      hostConfirmed
    }
  }
`;

export const SEND_CONTACT_US_EMAIL = gql`
  mutation sendContactUsEmail(
    $name: String!
    $email: String!
    $contactType: String!
    $message: String!
  ) {
    sendContactUsEmail(name: $name, email: $email, contactType: $contactType, message: $message)
  }
`;
