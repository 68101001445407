import React from 'react';
import { Alert as AntdAlert } from 'antd';
import { HeartFilled } from '@ant-design/icons';
import styled from 'styled-components';

const StyledAlert = styled.div`
  .ant-alert-warning {
    background-color: #1d1d1d;
  }
  .ant-alert-message {
    color: #fff;
    font-weight: 500;
  }
  .ant-alert-close-icon .anticon-close {
    color: #fff;
  }
`;

const Alert = () => (
  <StyledAlert>
    <AntdAlert
      message={`Love cooking and want to host your own pop-up? Contact us on Instagram!`}
      icon={<HeartFilled style={{ color: '#ff5757' }} />}
      banner
      closable={true}
    />
  </StyledAlert>
);

export default Alert;
