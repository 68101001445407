import React from 'react';
import Img from 'react-image';
import Loading from '../Loading';
import styled from 'styled-components';

const StyledImg = styled(Img).withConfig({
  shouldForwardProp: prop => !['fit', 'aspectRatio', 'useIntrinsicDimensions'].includes(prop),
})`
  object-fit: ${props => props.fit};
  height: ${props => (props.useIntrinsicDimensions ? 'auto' : '100%')};
  width: ${props => (props.useIntrinsicDimensions ? 'auto' : '100%')};
  ${props =>
    props.aspectRatio !== 'auto' &&
    `position: absolute;
     top: 0;
     right: 0;`}
  ${props =>
    props.useIntrinsicDimensions &&
    `max-height: 100%;
     max-width: 100%;`}
`;

export const ASPECT_RATIO = {
  auto: 'auto',
  four_by_three: '75%',
  one_by_one: '100%',
};

const Image = ({
  src,
  containerStyle,
  useIntrinsicDimensions = false,
  fit = 'cover',
  aspectRatio = ASPECT_RATIO.four_by_three,
}) => {
  return (
    // keeps an aspect ratio of 4:3.
    <div
      style={{
        minWidth: useIntrinsicDimensions ? 'auto' : '200px',
        width: useIntrinsicDimensions ? 'auto' : '100%',
        height: '100%',
        position: 'relative',
        paddingTop: aspectRatio,
        display: 'flex',
        ...containerStyle,
      }}
    >
      <StyledImg
        src={src}
        fit={fit}
        aspectRatio={aspectRatio}
        useIntrinsicDimensions={useIntrinsicDimensions}
        loader={
          <Loading
            simple
            style={{
              position: aspectRatio === ASPECT_RATIO.auto ? 'relative' : 'absolute',
              top: 0,
              right: 0,
              width: '100%',
            }}
          />
        }
      />
    </div>
  );
};

export default Image;
