import { useHistory } from 'react-router-dom';
import { useRef } from 'react';
import UniqueId from '../utils/UniqueId';

const useHistoryModalState = () => {
  const history = useHistory();
  const modalId = useRef(UniqueId());

  const { state = {} } = history.location;
  const opened = !!state[modalId.current];

  const open = () => {
    history.push({
      state: { ...state, [modalId.current]: true },
    });
  };
  const close = () => {
    history.replace({ state: { ...state, [modalId.current]: false } });
  };

  return { open, close, opened, modalId: modalId.current };
};

export default useHistoryModalState;
