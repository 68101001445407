import React, { useState } from 'react';
import { Form, Input, Button, notification } from 'antd';
import { useMutation } from '@apollo/react-hooks';
import get from 'lodash.get';
import { SEND_PASSWORD_RESET_EMAIL } from '../../utils/graphql';
import { MailOutlined } from '@ant-design/icons';
import customAntdRuleValidator from '../../utils/customAntdRuleValidator';
import validatorJs from 'validator';
import useRedirectTo from '../../hooks/useRedirectTo';
import { useLoginRegisterModal } from '../../context/loginRegister';
import RegisterButton from './RegisterButton';

const ForgotPasswordForm = ({ onFinish = () => {} }) => {
  const [errors, setErrors] = useState({});
  const redirectTo = useRedirectTo();
  const { close } = useLoginRegisterModal();

  const [
    sendPasswordResetEmail,
    { loading, data: { sendPasswordResetEmail: response } = {} },
  ] = useMutation(SEND_PASSWORD_RESET_EMAIL, {
    update() {
      notification.info({
        message: 'Email sent!',
        description:
          'If your email exists, you should have received an email from us to reset your password.',
      });
      close();
      onFinish();
    },
    onError: err => {
      setErrors(
        get(err, 'graphQLErrors[0].extensions.exception.errors', {
          general: 'Sending password reset email failed unexpectedly.',
        })
      );
    },
  });

  const onSubmit = values => sendPasswordResetEmail({ variables: { redirectTo, ...values } });

  return (
    <Form onFinish={onSubmit} layout="vertical">
      <Form.Item
        name="email"
        label="Email"
        rules={[
          { required: true, message: 'Please provide your email.' },
          customAntdRuleValidator(val => validatorJs.isEmail(val), 'Please provide a valid email.'),
        ]}
        {...(errors.email && {
          help: errors.email,
          validateStatus: 'error',
        })}
      >
        <Input prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Email" />
      </Form.Item>

      <Form.Item
        help={response && 'Email sent! Please check your inbox.'}
        validateStatus="success"
        {...(errors.general && {
          help: <b>{errors.general}</b>,
          validateStatus: 'error',
        })}
      >
        <Button
          type="primary"
          htmlType="submit"
          block
          loading={loading}
          disabled={response}
          style={{ marginBottom: '20px' }}
        >
          Reset My Password
        </Button>
        Or{' '}
        <RegisterButton type="link" style={{ padding: 0 }} onFinish={onFinish}>
          Register now!
        </RegisterButton>
      </Form.Item>
    </Form>
  );
};

export default ForgotPasswordForm;
