import React, { useState } from 'react';
import { LockOutlined, MailOutlined } from '@ant-design/icons';
import { Input, Button, Form } from 'antd';
import { useAuth } from '../../context/auth';
import { useMutation } from '@apollo/react-hooks';
import { LOGIN_USER } from '../../utils/graphql';
import get from 'lodash.get';
import { useLoginRegisterModal } from '../../context/loginRegister';
import RegisterButton from './RegisterButton';
import ForgotPasswordButton from './ForgotPasswordButton';

const LoginForm = ({ onFinish = () => {} }) => {
  const context = useAuth();
  const [errors, setErrors] = useState({});
  const { close } = useLoginRegisterModal();

  const [login, { loading }] = useMutation(LOGIN_USER, {
    update(_, { data: { login } }) {
      context.login(login.accessToken);
      close();
      onFinish();
    },
    onError(err) {
      setErrors(
        get(err, 'graphQLErrors[0].extensions.exception.errors', {
          general: 'Login failed unexpectedly.',
        })
      );
    },
  });

  const onSubmit = values => login({ variables: { ...values } });

  return (
    <Form onFinish={onSubmit} layout="vertical">
      <Form.Item
        name="email"
        label="Email"
        normalize={value => (value || '').trim().toLowerCase()}
        rules={[{ required: true, message: 'Please provide your email.' }]}
        {...(errors.email && {
          help: errors.email,
          validateStatus: 'error',
        })}
      >
        <Input prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Email" />
      </Form.Item>
      <Form.Item
        name="password"
        label="Password"
        rules={[{ required: true, message: 'Please provide your password.' }]}
        {...(errors.password && {
          help: errors.password,
          validateStatus: 'error',
        })}
      >
        <Input
          prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
          type="password"
          placeholder="Password"
        />
      </Form.Item>
      <Form.Item
        {...(errors.general && {
          help: <b>{errors.general}</b>,
          validateStatus: 'error',
        })}
      >
        <Button type="primary" htmlType="submit" block loading={loading}>
          Log in
        </Button>
      </Form.Item>
      <div>
        <ForgotPasswordButton type="link" style={{ padding: 0 }}>
          Forgot password?
        </ForgotPasswordButton>
      </div>
      Or{' '}
      <RegisterButton type="link" style={{ padding: 0 }} onFinish={onFinish}>
        Register Now!
      </RegisterButton>
    </Form>
  );
};

export default LoginForm;
