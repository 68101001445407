import moment from 'moment';

export const getISODate = (date, timezoneOffset = 'America/New_York') => {
  return formatDate(date, null, timezoneOffset);
};

export const formatDate = (
  date,
  format = 'llll z', // Sun, Mar 22, 2020 12:55 AM EDT
  timezoneOffset = 'America/New_York'
) => {
  return moment
    .utc(date)
    .tz(timezoneOffset)
    .format(format);
};

export const getTimeStops = (start, end, interval = { amount: 15, unit: 'minute' }) => {
  const { amount, unit } = interval;
  const startTime = moment(start);
  const endTime = moment(end);

  if (endTime.isBefore(startTime)) {
    endTime.add(1, 'day');
  }

  const timeStops = [];

  while (startTime <= endTime) {
    timeStops.push(new moment(startTime).format('hh:mm A'));
    startTime.add(amount, unit);
  }

  return timeStops;
};
