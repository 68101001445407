import React, { useState, useContext } from 'react';
import LoginRegisterModal from '../components/Common/LoginRegisterModal';
import { useAuth } from './auth';
import { notification } from 'antd';
import useRedirectTo from '../hooks/useRedirectTo';
import { useMutation } from '@apollo/react-hooks';
import { SEND_CONFIRMATION_EMAIL } from '../utils/graphql';
import get from 'lodash.get';
import useHistoryModalState from '../hooks/useHistoryModalState';

const LoginRegisterContext = React.createContext(null);

export const LoginRegisterFlow = {
  LOGIN: 'login',
  REGISTER: 'register',
  FORGOT_PASSWORD: 'forgot_password',
};

const noop = () => {};

const LoginRegisterProvider = ({ children, ...props }) => {
  const { open: openModal, close: closeModal, opened } = useHistoryModalState();
  const [flow, setFlow] = useState(LoginRegisterFlow.LOGIN);
  const [onFinishFn, setOnFinishFn] = useState(() => noop);
  const [onCancelFn, setOnCancelFn] = useState(() => noop);
  const { authenticatedUser } = useAuth();

  const open = (flow, onFinish, onCancel) => {
    onFinish = !onFinish ? noop : onFinish;
    onCancel = !onCancel ? noop : onCancel;
    setFlow(flow);
    openModal();
    setOnFinishFn(() => onFinish);
    setOnCancelFn(() => onCancel);
  };

  const close = () => {
    closeModal();
  };
  const redirectTo = useRedirectTo();

  const [sendConfirmationEmail] = useMutation(SEND_CONFIRMATION_EMAIL, {
    onError: err => {
      const error = get(err, 'graphQLErrors[0].extensions.exception.errors', {
        general: 'Sending email confirmation failed unexpectedly.',
      });

      notification.error({
        key: 'email_confirmation',
        message: `Sending confirmation email failed.`,
        description: error,
      });
    },
  });

  return (
    <LoginRegisterContext.Provider
      value={{
        opened,
        open,
        close,
        openLogin: (onFinish, onCancel) => {
          open(LoginRegisterFlow.LOGIN, onFinish, onCancel);
        },
        openRegister: (onFinish, onCancel) => open(LoginRegisterFlow.REGISTER, onFinish, onCancel),
        openForgotPassword: (onFinish, onCancel) =>
          open(LoginRegisterFlow.FORGOT_PASSWORD, onFinish, onCancel),
        sendEmailConfirmation: (onFinish, onCancel) => {
          if (!authenticatedUser) {
            notification.warn({
              message: 'You must login first.',
            });
            open(LoginRegisterFlow.LOGIN, onFinish, onCancel);
            return;
          }

          if (authenticatedUser.emailConfirmed) {
            notification.info({
              message: 'Your email is already confirmed!',
            });
          } else {
            notification.info({
              key: 'email_confirmation',
              message: `Sending...`,
              duration: null,
            });
            sendConfirmationEmail({
              variables: {
                redirectTo,
              },
            }).then(res =>
              notification.success({
                key: 'email_confirmation',
                message: `Email sent!`,
                description: `Please check your inbox at ${authenticatedUser.email}`,
              })
            );
          }
        },
      }}
      {...props}
    >
      <LoginRegisterModal
        opened={opened}
        flow={flow}
        onCancel={() => {
          close();
          onCancelFn();
        }}
        onFinish={onFinishFn}
      />
      {children}
    </LoginRegisterContext.Provider>
  );
};

const useLoginRegisterModal = () => useContext(LoginRegisterContext);

export { LoginRegisterContext, LoginRegisterProvider, useLoginRegisterModal };
