import React from 'react';
import { Layout } from 'antd';
import Navbar from './Navbar/Navbar';

const Header = ({ heightPx = 64 }) => (
  <Layout.Header
    style={{
      lineHeight: `${heightPx - 16}px`,
      position: 'fixed',
      width: '100%',
      zIndex: 10,
      backgroundColor: '#ffffff',
      height: `${heightPx}px`,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderBottom: '1px solid #ededed',
      padding: '0',
    }}
  >
    <Navbar />
  </Layout.Header>
);

export default Header;
