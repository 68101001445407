import React from 'react';
import { Typography, Layout } from 'antd';
import { InstagramFilled, FacebookFilled } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import Container from './Common/Container';
import styled from 'styled-components';

const { Text } = Typography;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 1rem;

  @media (min-width: 1200px) {
    max-width: 1170px;
  }

  @media (min-width: 992px) {
    max-width: 970px;
  }

  .textLinksContainer {
    display: flex;
    justify-content: flex-start;

    @media all and (max-width: 575px) {
      justify-content: center;
      flex-direction: column;
    }

    a {
      white-space: nowrap;
      margin: 5px 10px;
      font-weight: bold;
    }
  }

  .socialMediaLinksContainer {
    display: flex;
    align-items: flex-end;
    svg {
      margin: 0 5px;
      font-size: 30px;
    }
    @media all and (max-width: 575px) {
      justify-content: flex-start;
      svg {
        margin: 5px 5px 0 5px;
      }
    }
  }
`;

const socialMediaData = [
  {
    type: 'facebook',
    link: 'https://www.facebook.com/souskasa/',
    icon: <FacebookFilled />,
  },
  { type: 'instagram', link: 'https://www.instagram.com/sous.kasa/', icon: <InstagramFilled /> },
];

const Footer = () => {
  return (
    <Layout.Footer
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '20px 10%',
      }}
    >
      <FlexContainer>
        <div className="textLinksContainer">
          <Link to="/about-us">About Us</Link>
          <Link to="/contact-us">Contact Us</Link>
          <Link to="/terms">Terms</Link>
          <a href="https://www.onamenu.com/" target="_blank" rel="noopener noreferrer">
            Ona Menu
          </a>
        </div>
        <div className="socialMediaLinksContainer">
          {socialMediaData.map(socialMedia => (
            <a
              href={socialMedia.link}
              key={socialMedia.type}
              target="_blank"
              rel="noopener noreferrer"
            >
              {socialMedia.icon}
            </a>
          ))}
        </div>
      </FlexContainer>
      <Container center horizontal style={{ justifyContent: 'center', paddingTop: '10px' }}>
        <Text style={{ fontSize: 12 }}>©2020 Souskasa Inc. All Rights Reserved.</Text>
      </Container>
    </Layout.Footer>
  );
};

export default Footer;
