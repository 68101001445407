import React from 'react';
import { useSubscription } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { message } from 'antd';

const Test = () => {
  const { data } = useSubscription(newLocationSubscription);

  if (data) {
    message.success(`New Location! ${data.newLocation.id}`);
  }

  return <div></div>;
};

const newLocationSubscription = gql`
  subscription {
    newLocation {
      id
      name
      state
    }
  }
`;

export default Test;
