import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { LoadingIcon } from './icons';
import Center from './Common/Center';

const Loading = ({ text, simple = false, ...props }) => {
  const circleSpinner = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  return (
    <Center {...props}>
      {simple ? <Spin indicator={circleSpinner} /> : <LoadingIcon />}
      <span>{text}</span>
    </Center>
  );
};

export default Loading;
