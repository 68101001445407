import React, { useLayoutEffect } from 'react';
import LoginForm from './LoginForm';
import { Modal } from 'antd';
import { LoginRegisterFlow } from '../../context/loginRegister';
import RegisterForm from './RegisterForm';
import ForgotPasswordForm from './ForgotPasswordForm';

const LoginRegisterModal = ({ flow, opened, onCancel, onFinish }) => {
  const flowMap = {
    [LoginRegisterFlow.REGISTER]: {
      title: 'Register',
      component: <RegisterForm onFinish={onFinish} />,
    },
    [LoginRegisterFlow.LOGIN]: {
      title: 'Login',
      component: <LoginForm onFinish={onFinish} />,
    },
    [LoginRegisterFlow.FORGOT_PASSWORD]: {
      title: 'Reset Password',
      component: <ForgotPasswordForm onFinish={onFinish} />,
    },
  };

  useLayoutEffect(() => {
    if (opened) {
      window.scrollTo(0, 0);
    }
  }, [opened]);

  return (
    <Modal title={flowMap[flow].title} visible={opened} footer={null} onCancel={onCancel} centered>
      {flowMap[flow].component}
    </Modal>
  );
};

export default LoginRegisterModal;
