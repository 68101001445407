import React, { useState, useRef } from 'react';
import { Modal, Button, Slider, Popconfirm, notification, message } from 'antd';
import CanvasDraw from 'react-canvas-draw';
import { CirclePicker } from 'react-color';
import { UndoOutlined, DeleteOutlined, SaveOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/react-hooks';
import Container from '../Common/Container';
import gql from 'graphql-tag';
import get from 'lodash.get';
import { useIsMobile } from '../../hooks/useMediaQuery';
import { compressToUTF16 } from 'lz-string';
import { cheerFragment } from '../../utils/graphql';
import useUpload, { dataURItoBlob } from '../../hooks/useUpload';
import { FETCH_EVENT_QUERY } from '../../pages/Event';

const getCanvasData = canvasDraw => {
  return canvasDraw.current.getSaveData();
};

const saveCanvas = canvasDraw => {
  localStorage.setItem('savedDrawing', getCanvasData(canvasDraw));
};

const CheerModal = ({ eventId, hostId, visible, close }) => {
  const [brushColor, setBrushColor] = useState('#000000');
  const [brushRadius, setBrushRadius] = useState(3);

  const { upload } = useUpload();

  const [createCheer, { loading }] = useMutation(CREATE_CHEER_MUTATION, {
    update: (cache, { data: { createCheer } }) => {
      const { eventById: event } = cache.readQuery({
        query: FETCH_EVENT_QUERY,
        variables: { eventId: parseInt(eventId) },
      });
      const newEvent = { ...event };
      newEvent.cheers = [createCheer, ...event.cheers];
      cache.writeQuery({
        query: FETCH_EVENT_QUERY,
        variables: { eventId: parseInt(eventId) },
        data: { eventById: newEvent },
      });
    },
  });

  const canvasDraw = useRef();
  const isMobile = useIsMobile();

  const handleOk = async () => {
    try {
      const getRawUrl = await upload({
        contentType: 'text/html',
        fileName: `cheers_raw_${hostId}_${eventId}`,
        data: compressToUTF16(getCanvasData(canvasDraw)),
      });

      const getImageUrl = await upload({
        contentType: 'image/jpeg',
        fileName: `cheers_img_${hostId}_${eventId}`,
        data: dataURItoBlob(canvasDraw.current.canvas['drawing'].toDataURL()),
      });
      await createCheer({
        variables: {
          eventId: parseInt(eventId),
          hostId: parseInt(hostId),
          rawUrl: getRawUrl,
          imageUrl: getImageUrl,
        },
      });
      notification.success({
        message: 'Cheer Sent!',
        description: 'Your cheer has been sent. Thank you! :)',
      });

      // clear canvas
      canvasDraw.current.clear();
      saveCanvas(canvasDraw);
      close();
    } catch (err) {
      notification.error({
        message: 'Failed to send a cheer.',
        description: get(
          err,
          'graphQLErrors[0].extensions.message',
          'Sending cheer failed unexpectedly.'
        ),
      });
      saveCanvas(canvasDraw);
      close();
    }
  };

  const handleCancel = () => {
    saveCanvas(canvasDraw);
    close();
  };

  return (
    <Modal
      title="Draw & Send a Cheer"
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      centered
      destroyOnClose
      bodyStyle={{ padding: 0 }}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Save for Later
        </Button>,
        <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
          Send
        </Button>,
      ]}
    >
      <Container center>
        <Container
          horizontal
          style={{
            alignItems: 'center',
            padding: '0 0 10px 0',
            width: isMobile ? 300 : 400,
            justifyContent: 'flex-end',
          }}
        >
          <Popconfirm
            title="Clear the canvas?"
            onConfirm={() => canvasDraw.current.clear()}
            placement="left"
            okText="Yes"
            cancelText="No"
          >
            <Button type="link" style={{ padding: '0' }}>
              <DeleteOutlined style={{ fontSize: '25px' }} />
            </Button>
          </Popconfirm>
          <Button
            type="link"
            onClick={() => canvasDraw.current.undo()}
            style={{ padding: '0 0 0 20px' }}
          >
            <UndoOutlined style={{ fontSize: '25px' }} />
          </Button>
          <Button
            type="link"
            onClick={() => {
              saveCanvas(canvasDraw);
              message.success('Saved.');
            }}
            style={{ padding: '0 0 0 20px' }}
          >
            <SaveOutlined style={{ fontSize: '25px' }} />
          </Button>
        </Container>
        <CanvasDraw
          ref={canvasDraw}
          saveData={localStorage.getItem('savedDrawing')}
          immediateLoading
          lazyRadius={1}
          hideGrid
          brushColor={brushColor}
          catenaryColor={brushColor}
          brushRadius={brushRadius}
          canvasWidth={isMobile ? 300 : 400}
          canvasHeight={isMobile ? 300 : 400}
          style={{ outline: '3px solid', position: 'relative' }}
          hideInterface={isMobile}
        />
        <Container center style={{ alignItems: 'center' }}>
          <CirclePicker
            width={isMobile ? 270 : 324}
            circleSize={isMobile ? 30 : 36}
            circleSpacing={isMobile ? 15 : 18}
            color={brushColor}
            onChangeComplete={color => setBrushColor(color.hex)}
            colors={[
              '#de1e10',
              '#e91e63',
              '#9c27b0',
              '#673ab7',
              '#3f51b5',
              '#2196f3',
              '#00bcd4',
              '#009688',
              '#4caf50',
              '#8bc34a',
              '#cddc39',
              '#ffeb3b',
              '#ffc107',
              '#ff9800',
              '#ff5722',
              '#795548',
              '#f3f3f3',
              '#141414',
            ]}
          />
          <Container horizontal style={{ alignItems: 'center', paddingTop: '10px' }}>
            <Slider
              min={1}
              max={5}
              style={{ width: isMobile ? 270 : 324 }}
              onChange={size => setBrushRadius(size)}
              value={typeof brushRadius === 'number' ? brushRadius : 0}
              marks={{ 1: '1px', 2: '2px', 3: '3px', 4: '4px', 5: '5px' }}
            />
          </Container>
        </Container>
      </Container>
    </Modal>
  );
};

const CREATE_CHEER_MUTATION = gql`
  mutation createCheer($hostId: Float!, $eventId: Float!, $rawUrl: String!, $imageUrl: String!) {
    createCheer(hostId: $hostId, eventId: $eventId, rawUrl: $rawUrl, imageUrl: $imageUrl) {
      ...CheerFragment
    }
  }
  ${cheerFragment}
`;

export default CheerModal;
